import './App.css';
import { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { notificationsSelectors } from './store/notifications';
import { AdminRoute, OPSConsoleRoute, Path, PrivateRoute } from './routing';
import {
  Account,
  AccountDetails,
  Accounts,
  Availability,
  BookingPages,
  CalendarConnections,
  ConnectIntegrationPage,
  EditBookingPage,
  EditBookingTemplate,
  EditWorkspace,
  BookingTemplates,
  PublicBookingPage,
  PublicRoute,
  QuickSetup,
  Roles,
  ScheduledMeetings,
  Tests,
  ChargebeePoC,
  LicenseExpired,
  Workspaces,
  Users,
  Orgs,
  OrgDetails,
  StaffPage,
  StaffDetails,
  OPSConsoleUsers,
  UserDetails,
  ViewAsUser,
  Teams,
  EditGroupBookingPage,
  PublicGroupBookingPage,
} from './pages';
import { MyLogin, Profile, MyRole } from './components/account';
import { ToastNotification, GlobalError } from './components/common';
import { authentificationSelectors } from './store/authentification';
import { MicrosoftAuthRedirect, Login, SignUp } from './components/public';
import { Integration } from './pages/integration/Integration';
import { Redirect } from './routing/Redirect';
import { Billing } from './pages/billing/Billing';
import { navigationService } from './services/NavigationService';
import { LayoutContext } from './components/common/context/layoutcontext';

export default function App() {
  const location = useLocation();
  const toastNotificationsQueue = useSelector(notificationsSelectors.selectToastNotificationsQueue);
  const isSumo1Admin = useSelector(authentificationSelectors.selectIsSumo1Admin);
  const { layoutConfig, changeColorScheme } = useContext(LayoutContext);

  // set up navigation service to use across application
  const navigate = useNavigate();
  useEffect(() => {
    navigationService.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    const isOPSConsole = isSumo1Admin && location.pathname.startsWith(Path.OPSConsole);
    const newTheme = isOPSConsole ? 'dim' : 'light';
    const crispChat = document.querySelector('.crisp-client');
    if (newTheme !== layoutConfig.colorScheme) {
      // hide crispChat for OPS Console
      if (crispChat) {
        newTheme === 'dim' ? crispChat.classList.add('hidden') : crispChat.classList.remove('hidden');
      }
      changeColorScheme(newTheme);
    }
  }, [isSumo1Admin, location]);

  return (
    <>
      <Routes>
        <Route path={Path.OPSConsole} element={<OPSConsoleRoute />}>
          <Route path={Path.OPSConsoleAccounts} element={<Accounts />} />
          <Route path={Path.OPSConsoleAccount} element={<AccountDetails />} />
          <Route path={Path.OPSConsoleOrgs} element={<Orgs />} />
          <Route path={Path.OPSConsoleOrgDetails} element={<OrgDetails />} />
          <Route path={Path.OPSConsoleStaff} element={<StaffPage />} />
          <Route path={Path.OPSConsoleStaffDetails} element={<StaffDetails />} />
          <Route path={Path.OPSConsoleUsers} element={<OPSConsoleUsers />} />
          <Route path={Path.OPSConsoleUserDetails} element={<UserDetails />} />
          <Route path={Path.OPSConsoleBilling} />
          <Route path={Path.OPSConsoleViewAsUser} element={<ViewAsUser />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route index element={<SignUp />} />
          <Route path={Path.Authorise} element={<Redirect path={Path.Landing} />} />
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.MicrosoftAuthRedirect} element={<MicrosoftAuthRedirect />} />
          <Route path={Path.Register} element={<Redirect path={Path.Landing} />} />
          <Route path={Path.LicenseExpired} element={<LicenseExpired />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={Path.Account} element={<Account />}>
            <Route index element={<Profile />} />
            {/* <Route path={Path.MyLink} element={<MyLink />} /> */}
            <Route path={Path.MyLogin} element={<MyLogin />} />
            <Route path={Path.MyRole} element={<MyRole />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path={Path.Billing} element={<Billing />} />
            <Route path={Path.EditBookingTemplate} element={<EditBookingTemplate />} />
            <Route path={Path.EditWorkspace} element={<EditWorkspace />} />
            <Route path={Path.BookingTemplates} element={<BookingTemplates />} />
            <Route path={Path.Roles} element={<Roles />} />
            <Route path={Path.Users} element={<Users />} />
            <Route path={Path.Workspaces} element={<Workspaces />} />
            <Route path={Path.Teams} element={<Teams />} />
          </Route>
          <Route path={Path.Availability} element={<Availability />} />
          <Route path={Path.BookingPages} element={<BookingPages />} />
          <Route path={Path.CalendarConnections} element={<CalendarConnections />} />
          <Route path={Path.ConnectIntegration} element={<ConnectIntegrationPage />} />
          <Route path={Path.EditBookingPage} element={<EditBookingPage />} />
          <Route path={Path.EditGroupBookingPage} element={<EditGroupBookingPage />} />
          <Route path={Path.Integration} element={<Integration />} />
          <Route path={Path.BookedMeetings} element={<ScheduledMeetings />} />
          <Route path={Path.QuickSetup} element={<QuickSetup />} />
          <Route path={Path.BillingTests} element={<ChargebeePoC />} />
          <Route path={Path.Tests} element={<Tests />} />
        </Route>
        <Route path={Path.UnknownPath} element={<GlobalError />} />
        <Route path={Path.PublicBookingPage} element={<PublicBookingPage />} />
        <Route path={Path.PublicGroupBookingPage} element={<PublicGroupBookingPage />} />
        <Route path={Path.Event} element={<PublicBookingPage />} />
      </Routes>
      {Boolean(toastNotificationsQueue.length) && <ToastNotification />}
    </>
  );
}
