/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTeamRoundRobin = /* GraphQL */ `query GetTeamRoundRobin($teamId: String!) {
  getTeamRoundRobin(teamId: $teamId) {
    teamId
    userId
    lastCreatedEvent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamRoundRobinQueryVariables,
  APITypes.GetTeamRoundRobinQuery
>;
export const listTeamRoundRobins = /* GraphQL */ `query ListTeamRoundRobins(
  $teamId: String
  $filter: ModelTeamRoundRobinFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeamRoundRobins(
    teamId: $teamId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      teamId
      userId
      lastCreatedEvent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamRoundRobinsQueryVariables,
  APITypes.ListTeamRoundRobinsQuery
>;
export const getTeamUserRoundRobin = /* GraphQL */ `query GetTeamUserRoundRobin($teamId: String!, $userId: String!) {
  getTeamUserRoundRobin(teamId: $teamId, userId: $userId) {
    teamId
    userId
    lastCreatedEvent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamUserRoundRobinQueryVariables,
  APITypes.GetTeamUserRoundRobinQuery
>;
export const listTeamUserRoundRobins = /* GraphQL */ `query ListTeamUserRoundRobins(
  $teamId: String
  $userId: ModelStringKeyConditionInput
  $filter: ModelTeamUserRoundRobinFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeamUserRoundRobins(
    teamId: $teamId
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      teamId
      userId
      lastCreatedEvent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamUserRoundRobinsQueryVariables,
  APITypes.ListTeamUserRoundRobinsQuery
>;
export const teamRoundRobinsByUserId = /* GraphQL */ `query TeamRoundRobinsByUserId(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamRoundRobinFilterInput
  $limit: Int
  $nextToken: String
) {
  teamRoundRobinsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamId
      userId
      lastCreatedEvent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamRoundRobinsByUserIdQueryVariables,
  APITypes.TeamRoundRobinsByUserIdQuery
>;
export const teamUserRoundRobinsByUserId = /* GraphQL */ `query TeamUserRoundRobinsByUserId(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamUserRoundRobinFilterInput
  $limit: Int
  $nextToken: String
) {
  teamUserRoundRobinsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamId
      userId
      lastCreatedEvent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamUserRoundRobinsByUserIdQueryVariables,
  APITypes.TeamUserRoundRobinsByUserIdQuery
>;
export const getUserData = /* GraphQL */ `query GetUserData($userId: String!, $link: String!, $tenant: String!) {
  getUserData(userId: $userId, link: $link, tenant: $tenant) {
    userId
    tenant
    owner
    link
    recordType
    userSettings {
      userDefaultAvailabilityId
      avatar
      fullName
      phoneDetails
      language
      dateFormat
      timeFormat
      country
      countryCode
      timeZone
      phoneNumber
      email
      isAdmin
      isQuickSetupFinished
      isInvitePeopleClosed
      defaultVideoIntegration
      calendarWarningDate
      __typename
    }
    availabilityData {
      id
      name
      activeBookingPages
      isDefault
      __typename
    }
    statistics {
      eventCreated
      emailsSent
      eventRescheduled
      eventCanceled
      __typename
    }
    lastActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserDataQueryVariables,
  APITypes.GetUserDataQuery
>;
export const listUserData = /* GraphQL */ `query ListUserData(
  $userId: String
  $linkTenant: ModelUserDataPrimaryCompositeKeyConditionInput
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserData(
    userId: $userId
    linkTenant: $linkTenant
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserDataQueryVariables,
  APITypes.ListUserDataQuery
>;
export const userDataByTenant = /* GraphQL */ `query UserDataByTenant(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
) {
  userDataByTenant(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserDataByTenantQueryVariables,
  APITypes.UserDataByTenantQuery
>;
export const userDataByLink = /* GraphQL */ `query UserDataByLink(
  $link: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
) {
  userDataByLink(
    link: $link
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserDataByLinkQueryVariables,
  APITypes.UserDataByLinkQuery
>;
export const getUserEvent = /* GraphQL */ `query GetUserEvent(
  $userId: String!
  $eventDate: AWSDate!
  $eventId: String!
  $tenant: String!
) {
  getUserEvent(
    userId: $userId
    eventDate: $eventDate
    eventId: $eventId
    tenant: $tenant
  ) {
    userId
    tenant
    eventDate
    eventId
    editKey
    shortLink
    shortEditLink
    bookingPageId
    bookingPageName
    owner
    startTime
    endTime
    beforeStartTime
    afterEndTime
    location {
      type
      settings
      __typename
    }
    integrations {
      type
      settings
      __typename
    }
    timeZone
    note
    cohosts
    guestEmails
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    canceled {
      isCanceled
      type
      note
      __typename
    }
    executionIds {
      emailExecutionIds
      smsExecutionIds
      __typename
    }
    isNoShow
    host
    internalNotes
    isSMSAllowed
    hostName
    cohostNames
    labels {
      eventNameLabel
      bookingTemplateLabel
      detailsLabel
      dateLabel
      timeLabel
      informationLabel
      additionalLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      avatar
      backgroundImage
      opacity
      css
      footer
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserEventQueryVariables,
  APITypes.GetUserEventQuery
>;
export const listUserEvents = /* GraphQL */ `query ListUserEvents(
  $userId: String
  $eventDateEventIdTenant: ModelUserEventPrimaryCompositeKeyConditionInput
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserEvents(
    userId: $userId
    eventDateEventIdTenant: $eventDateEventIdTenant
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      cohosts
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cohostNames
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserEventsQueryVariables,
  APITypes.ListUserEventsQuery
>;
export const userEventsByTenant = /* GraphQL */ `query UserEventsByTenant(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByTenant(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      cohosts
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cohostNames
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByTenantQueryVariables,
  APITypes.UserEventsByTenantQuery
>;
export const userEventsByEventDate = /* GraphQL */ `query UserEventsByEventDate(
  $eventDate: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByEventDate(
    eventDate: $eventDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      cohosts
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cohostNames
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByEventDateQueryVariables,
  APITypes.UserEventsByEventDateQuery
>;
export const userEventsByEventId = /* GraphQL */ `query UserEventsByEventId(
  $eventId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      cohosts
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cohostNames
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByEventIdQueryVariables,
  APITypes.UserEventsByEventIdQuery
>;
export const getAdminData = /* GraphQL */ `query GetAdminData($email: String!, $tenantId: String!) {
  getAdminData(email: $email, tenantId: $tenantId) {
    email
    userId
    roleId
    status
    tenantId
    workspaceIds
    invitedById
    invitedByName
    team
    bookingTemplateIds
    expirationDate
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminDataQueryVariables,
  APITypes.GetAdminDataQuery
>;
export const listAdminData = /* GraphQL */ `query ListAdminData(
  $email: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelAdminDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAdminData(
    email: $email
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      userId
      roleId
      status
      tenantId
      workspaceIds
      invitedById
      invitedByName
      team
      bookingTemplateIds
      expirationDate
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminDataQueryVariables,
  APITypes.ListAdminDataQuery
>;
export const adminDataByTenantId = /* GraphQL */ `query AdminDataByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAdminDataFilterInput
  $limit: Int
  $nextToken: String
) {
  adminDataByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      userId
      roleId
      status
      tenantId
      workspaceIds
      invitedById
      invitedByName
      team
      bookingTemplateIds
      expirationDate
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminDataByTenantIdQueryVariables,
  APITypes.AdminDataByTenantIdQuery
>;
export const getTenant = /* GraphQL */ `query GetTenant($tenantId: String!) {
  getTenant(tenantId: $tenantId) {
    tenantId
    name
    status
    tenantStyle {
      avatar
      backgroundImage
      opacity
      css
      footer
      __typename
    }
    workspace {
      id
      name
      isActive
      isPhoneRequired
      bookingPageIndex
      __typename
    }
    type
    note
    subscription
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenantQueryVariables, APITypes.GetTenantQuery>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $tenantId: String
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTenants(
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      name
      status
      type
      note
      subscription
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const getBookingTemplate = /* GraphQL */ `query GetBookingTemplate($id: String!, $workspaceId: String!) {
  getBookingTemplate(id: $id, workspaceId: $workspaceId) {
    id
    workspaceId
    enabled
    inviteOthers
    enterNote
    labels {
      eventNameLabel
      bookingTemplateLabel
      detailsLabel
      dateLabel
      timeLabel
      informationLabel
      additionalLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      avatar
      backgroundImage
      opacity
      css
      footer
      __typename
    }
    calendar {
      timeFormat
      calendarFormat
      timeInterval
      topOfInterval
      timeZoneType
      selectedTimeZone
      defaultTimeZone
      __typename
    }
    potentialHosts
    potentialTeams
    what {
      customName
      color
      __typename
    }
    where {
      locationTypes
      videoConferenceType
      defaultLocationType
      inPersonType
      customAddress
      locations
      phoneCallType
      customPhone
      customCountryCode
      skipTheWhereStep
      __typename
    }
    when {
      afterEndTime
      beforeStartTime
      eventsPerDay
      __typename
    }
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    notifications {
      __typename
    }
    confirmationsPage {
      type
      externalLink
      anotherEventEnabled
      rescheduleEnabled
      cancelEnabled
      cancelationPolicy
      __typename
    }
    lastModify
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingTemplateQueryVariables,
  APITypes.GetBookingTemplateQuery
>;
export const listBookingTemplates = /* GraphQL */ `query ListBookingTemplates(
  $id: String
  $workspaceId: ModelStringKeyConditionInput
  $filter: ModelBookingTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingTemplates(
    id: $id
    workspaceId: $workspaceId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      workspaceId
      enabled
      inviteOthers
      enterNote
      potentialHosts
      potentialTeams
      lastModify
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingTemplatesQueryVariables,
  APITypes.ListBookingTemplatesQuery
>;
export const bookingTemplatesByWorkspaceId = /* GraphQL */ `query BookingTemplatesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingTemplatesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workspaceId
      enabled
      inviteOthers
      enterNote
      potentialHosts
      potentialTeams
      lastModify
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingTemplatesByWorkspaceIdQueryVariables,
  APITypes.BookingTemplatesByWorkspaceIdQuery
>;
export const getBookingPage = /* GraphQL */ `query GetBookingPage($id: String!, $workspaceId: String!) {
  getBookingPage(id: $id, workspaceId: $workspaceId) {
    id
    workspaceId
    inviteOthers
    enterNote
    shortLink
    adminOnly
    labels {
      eventNameLabel
      bookingTemplateLabel
      detailsLabel
      dateLabel
      timeLabel
      informationLabel
      additionalLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      avatar
      backgroundImage
      opacity
      css
      footer
      __typename
    }
    calendar {
      timeFormat
      calendarFormat
      timeInterval
      topOfInterval
      timeZoneType
      selectedTimeZone
      defaultTimeZone
      __typename
    }
    what {
      customName
      color
      bookingTemplateId
      isActive
      __typename
    }
    who {
      hostAssignmentMode
      hostRescheduleWith
      __typename
    }
    where {
      locationTypes
      videoConferenceType
      defaultLocationType
      inPersonType
      customAddress
      locations
      phoneCallType
      customPhone
      customCountryCode
      skipTheWhereStep
      __typename
    }
    when {
      afterEndTime
      beforeStartTime
      eventsPerDay
      __typename
    }
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    notifications {
      __typename
    }
    confirmationsPage {
      type
      externalLink
      anotherEventEnabled
      rescheduleEnabled
      cancelEnabled
      cancelationPolicy
      __typename
    }
    lastModify
    displayId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingPageQueryVariables,
  APITypes.GetBookingPageQuery
>;
export const listBookingPages = /* GraphQL */ `query ListBookingPages(
  $id: String
  $workspaceId: ModelStringKeyConditionInput
  $filter: ModelBookingPageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingPages(
    id: $id
    workspaceId: $workspaceId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      workspaceId
      inviteOthers
      enterNote
      shortLink
      adminOnly
      lastModify
      displayId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingPagesQueryVariables,
  APITypes.ListBookingPagesQuery
>;
export const bookingPagesByWorkspaceId = /* GraphQL */ `query BookingPagesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingPagesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workspaceId
      inviteOthers
      enterNote
      shortLink
      adminOnly
      lastModify
      displayId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingPagesByWorkspaceIdQueryVariables,
  APITypes.BookingPagesByWorkspaceIdQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: String!, $tenantId: String!) {
  getRole(id: $id, tenantId: $tenantId) {
    id
    tenantId
    name
    isActive
    isCustom
    hasChromeAccess
    users
    availability
    myBookingPages
    allBookingPages
    bookingTemplates
    resources
    teams
    isAccessToAllWorkspaces
    workspaceAdminActions
    updatedBy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $id: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRoles(
    id: $id
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      name
      isActive
      isCustom
      hasChromeAccess
      users
      availability
      myBookingPages
      allBookingPages
      bookingTemplates
      resources
      teams
      isAccessToAllWorkspaces
      workspaceAdminActions
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const rolesByTenantId = /* GraphQL */ `query RolesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  rolesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      name
      isActive
      isCustom
      hasChromeAccess
      users
      availability
      myBookingPages
      allBookingPages
      bookingTemplates
      resources
      teams
      isAccessToAllWorkspaces
      workspaceAdminActions
      updatedBy
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RolesByTenantIdQueryVariables,
  APITypes.RolesByTenantIdQuery
>;
export const getLicense = /* GraphQL */ `query GetLicense($id: String!, $tenantId: String!) {
  getLicense(id: $id, tenantId: $tenantId) {
    id
    tenantId
    type
    startDate
    endDate
    owned
    assigned
    hasScheduledChanges
    scheduledChanges {
      id
      seats
      plan
      changeBy
      requestDate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLicenseQueryVariables,
  APITypes.GetLicenseQuery
>;
export const listLicenses = /* GraphQL */ `query ListLicenses(
  $id: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelLicenseFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLicenses(
    id: $id
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      type
      startDate
      endDate
      owned
      assigned
      hasScheduledChanges
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLicensesQueryVariables,
  APITypes.ListLicensesQuery
>;
export const licensesByTenantId = /* GraphQL */ `query LicensesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLicenseFilterInput
  $limit: Int
  $nextToken: String
) {
  licensesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      type
      startDate
      endDate
      owned
      assigned
      hasScheduledChanges
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LicensesByTenantIdQueryVariables,
  APITypes.LicensesByTenantIdQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: String!) {
  getAccount(id: $id) {
    id
    tenantIds
    name
    createdBy
    note
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $id: String
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccounts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantIds
      name
      createdBy
      note
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getStaff = /* GraphQL */ `query GetStaff($email: String!) {
  getStaff(email: $email) {
    email
    userId
    userName
    roleName
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStaffQueryVariables, APITypes.GetStaffQuery>;
export const listStaff = /* GraphQL */ `query ListStaff(
  $email: String
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStaff(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      userId
      userName
      roleName
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListStaffQueryVariables, APITypes.ListStaffQuery>;
export const getTeam = /* GraphQL */ `query GetTeam($id: String!) {
  getTeam(id: $id) {
    id
    workspaceId
    name
    active
    workspacesForRead
    members {
      userId
      priority
      __typename
    }
    assignmentMode
    rescheduleWith
    createdBy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $id: String
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeams(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      workspaceId
      name
      active
      workspacesForRead
      assignmentMode
      rescheduleWith
      createdBy
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const teamsByWorkspaceId = /* GraphQL */ `query TeamsByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workspaceId
      name
      active
      workspacesForRead
      assignmentMode
      rescheduleWith
      createdBy
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamsByWorkspaceIdQueryVariables,
  APITypes.TeamsByWorkspaceIdQuery
>;
export const getGroupBookingPage = /* GraphQL */ `query GetGroupBookingPage($id: String!) {
  getGroupBookingPage(id: $id) {
    id
    tenantId
    workspaceId
    name
    active
    adminOnly
    labels {
      eventNameLabel
      bookingTemplateLabel
      detailsLabel
      dateLabel
      timeLabel
      informationLabel
      additionalLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      avatar
      backgroundImage
      opacity
      css
      footer
      __typename
    }
    shortLink
    bookingPages {
      bookingPageId
      description
      __typename
    }
    brandingBookingPageId
    createdBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupBookingPageQueryVariables,
  APITypes.GetGroupBookingPageQuery
>;
export const listGroupBookingPages = /* GraphQL */ `query ListGroupBookingPages(
  $id: String
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGroupBookingPages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupBookingPagesQueryVariables,
  APITypes.ListGroupBookingPagesQuery
>;
export const groupBookingPagesByTenantId = /* GraphQL */ `query GroupBookingPagesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  groupBookingPagesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupBookingPagesByTenantIdQueryVariables,
  APITypes.GroupBookingPagesByTenantIdQuery
>;
export const groupBookingPagesByWorkspaceId = /* GraphQL */ `query GroupBookingPagesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  groupBookingPagesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupBookingPagesByWorkspaceIdQueryVariables,
  APITypes.GroupBookingPagesByWorkspaceIdQuery
>;
