import { t } from '../../../i18n/i18n';

export default {
  infoMessage: t('EditGroupBookingPage:BASIC_SETTING_INFO_MESSAGE'),
  nameTitle: t('EditGroupBookingPage:NAME_TITLE'),
  namePlaceholder: t('EditGroupBookingPage:NAME_PLACEHOLDER'),
  adminOnly: t('EditGroupBookingPage:ADMIN_ONLY_TITLE'),
  bookingPages: t('EditGroupBookingPage:BOOKING_PAGES_TITLE'),
  bookingPagesDesc: t('EditGroupBookingPage:BOOKING_PAGES_DESCRIPTION'),
  nameTooltip: t('BookingPageWhatStep:NAME_TOOLTIP'),
  adminOnlyTooltip: t('BookingPageWhatStep:ADMIN_ONLY_TOOLTIP'),
  active: t('BookingPageWhatStep:ACTIVE'),
};
