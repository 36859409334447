import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { UpdateBookingPageInput, UpdateGroupBookingPageInput } from '../../API';
import { groupBookingPagesActionTypes, groupBookingPagesActions } from './actions';
import { deleteGroupBookingPage, getGroupBookingPage, getGroupBookingPages, upsertGroupBookingPage } from './service';
import { authentificationSelectors } from '../authentification';
import { GetGroupBookingPageResponse } from './types';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { bookingPagesSagas } from '../bookingPages/sagas';
import {
  ACTIVATE_GROUP_PAGE_SUCCESS_TOAST,
  CLONE_GROUP_PAGE_ERROR_TOAST,
  CLONE_GROUP_PAGE_SUCCESS_TOAST,
  DEACTIVATE_GROUP_PAGE_SUCCESS_TOAST,
  DEFAULT_GROUP_BOOKING_PAGE,
  DELETE_GROUP_PAGES_ERROR_TOAST,
  DELETE_GROUP_PAGES_SUCCESS_TOAST,
  GET_GROUP_PAGE_ERROR_TOAST,
  GET_GROUP_PAGES_ERROR_TOAST,
  SAVE_GROUP_PAGE_ERROR_TOAST,
  SAVE_GROUP_PAGE_SUCCESS_TOAST,
} from './constants';
import { bookingPagesActions, bookingPageSelectors } from '../bookingPages';
import { groupBookingPagesSelectors } from './selectors';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';
import { notificationsActions } from '../notifications';

const selectCloneGroupBookingPageRequest = createSelector(
  authentificationSelectors.selectWorkspace,
  authentificationSelectors.selectUserId,
  groupBookingPagesSelectors.selectGroupBookingPage,
  groupBookingPagesSelectors.selectCloneName,
  (workspaceId, userId, groupBookingPage, cloneName) => ({
    ...groupBookingPage,
    id: '',
    workspaceId,
    adminOnly: false,
    createdBy: userId,
    name: cloneName,
  })
);

function* getGroupBookingPagesSaga() {
  try {
    const workspaceId: string = yield select(authentificationSelectors.selectWorkspace);
    const response: GetGroupBookingPageResponse = yield call(getGroupBookingPages, workspaceId);
    yield put(
      groupBookingPagesActions.getGroupBookingPagesSuccess(
        response.fullGroupBookingPages.map((record) => record.groupBookingPage)
      )
    );
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.getGroupBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, GET_GROUP_PAGES_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* getGroupBookingPageSaga(action: ReturnType<typeof groupBookingPagesActions.getGroupBookingPageRequest>) {
  try {
    if (action.type === groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST) {
      const id = action.payload.id;
      const isInitial = action.payload.isInitialVisit;
      const userId: string = yield select(authentificationSelectors.selectUserId);
      const currentWorkspaceId: string = yield select(authentificationSelectors.selectWorkspace);
      let groupBookingPage: UpdateGroupBookingPageInput;

      const requestsList = [
        ...(id ? [call(getGroupBookingPage, id)] : []),
        ...(!isInitial ? [call(bookingPagesSagas.getBookingPages)] : []),
      ];
      const [response]: [GetGroupBookingPageResponse] = yield all(requestsList);

      if (!id) {
        const bookingPages: string[] = yield select(bookingPageSelectors.selectSelectedBookingPages);
        const bookingPage: UpdateBookingPageInput = yield select(
          bookingPageSelectors.selectBookingPageById(bookingPages[0])
        );
        groupBookingPage = {
          ...DEFAULT_GROUP_BOOKING_PAGE,
          workspaceId: currentWorkspaceId,
          bookingPages: bookingPages.map((id) => ({ bookingPageId: id })),
          createdBy: userId,
          brandingBookingPageId: bookingPage.id,
          style: bookingPage.style,
          labels: bookingPage.labels,
        };
      } else if (response && response.fullGroupBookingPages) {
        const item = response.fullGroupBookingPages[0];
        groupBookingPage = item.groupBookingPage;
      } else {
        throw new Error('GroupBookingPage not found');
      }
      yield put(groupBookingPagesActions.getGroupBookingPageSuccess(groupBookingPage));
    }
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.getGroupBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, GET_GROUP_PAGE_ERROR_TOAST, true);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* getPublicGroupBookingPageSaga(
  action: ReturnType<typeof groupBookingPagesActions.getPublicGroupBookingPageRequest>
) {
  try {
    if (action.type === groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST) {
      const id = action.payload.id;

      const response: GetGroupBookingPageResponse = yield call(getGroupBookingPage, id);

      if (response && response.fullGroupBookingPages) {
        const item = response.fullGroupBookingPages[0];
        const groupBookingPage = item.groupBookingPage;

        if (!groupBookingPage.active) {
          throw new Error('Group Booking Page inactive');
        }

        if (item.bookingPages) {
          yield put(bookingPagesActions.getBookingPagesSuccess(item.bookingPages));
        }

        yield put(groupBookingPagesActions.getGroupBookingPageSuccess(groupBookingPage));
      } else {
        throw new Error('Group Booking Page not found');
      }
    }
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.getGroupBookingPageFail(error?.toString()));
  }
}

function* upsertGroupBookingPageSaga() {
  try {
    const groupPage: UpdateGroupBookingPageInput = yield select(groupBookingPagesSelectors.selectGroupBookingPage);
    yield call(upsertGroupBookingPage, groupPage);
    yield put(groupBookingPagesActions.upsertGroupBookingPageSuccess());
    yield call(navigationService.navigateTo, Path.BookingPages);
    yield put<any>(notificationsActions.showToast(SAVE_GROUP_PAGE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.upsertGroupBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_GROUP_PAGE_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* activateGroupBookingPageSaga() {
  try {
    const groupPage: UpdateGroupBookingPageInput = yield select(groupBookingPagesSelectors.selectGroupBookingPage);
    yield call(upsertGroupBookingPage, { ...groupPage, active: !groupPage.active });
    yield put(groupBookingPagesActions.activateGroupBookingPageSuccess());
    yield put(groupBookingPagesActions.getGroupBookingPagesRequest());

    yield put<any>(
      notificationsActions.showToast(
        groupPage.active ? DEACTIVATE_GROUP_PAGE_SUCCESS_TOAST : ACTIVATE_GROUP_PAGE_SUCCESS_TOAST
      )
    );
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.activateGroupBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_GROUP_PAGE_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* deleteGroupBookingPageSaga(
  action: ReturnType<typeof groupBookingPagesActions.deleteGroupBookingPageRequest>
) {
  try {
    if (action.type === groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_REQUEST) {
      yield call(deleteGroupBookingPage, action.payload);
      yield put(groupBookingPagesActions.deleteGroupBookingPageSuccess());
      yield put(groupBookingPagesActions.getGroupBookingPagesRequest());
      yield put<any>(notificationsActions.showToast(DELETE_GROUP_PAGES_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.deleteGroupBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_GROUP_PAGES_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* cloneGroupBookingPageSaga() {
  try {
    const cloneGroupPage: UpdateGroupBookingPageInput = yield select(selectCloneGroupBookingPageRequest);
    yield call(upsertGroupBookingPage, cloneGroupPage);
    yield put(groupBookingPagesActions.cloneGroupBookingPageSuccess());
    yield put(groupBookingPagesActions.getGroupBookingPagesRequest());
    yield put<any>(notificationsActions.showToast(CLONE_GROUP_PAGE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(groupBookingPagesActions.cloneGroupBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_GROUP_PAGE_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

export function* watchGroupBookingPagesSaga() {
  yield takeLatest(groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST, getGroupBookingPageSaga);
  yield takeLatest(groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST, getPublicGroupBookingPageSaga);
  yield takeLatest(groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_REQUEST, getGroupBookingPagesSaga);
  yield takeLatest(groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_REQUEST, upsertGroupBookingPageSaga);
  yield takeLatest(groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_REQUEST, activateGroupBookingPageSaga);
  yield takeLatest(groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_REQUEST, deleteGroupBookingPageSaga);
  yield takeLatest(groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_REQUEST, cloneGroupBookingPageSaga);
}

export const groupBookingPagesSagas = {
  getGroupBookingPages: getGroupBookingPagesSaga,
};
