import { call, put, takeLatest } from 'redux-saga/effects';
import { GlobalActionTypes, globalActions } from './actions';
import { UserDetails, UserSettingsKeys, userSettingsActions } from '../userSettings';
import { SagaIterator } from 'redux-saga';
import { getAuthentification } from '../authentification/service';
import { AuthentificationData, UserDataInputCreatedAt } from './types';
import { PermissionAction, UserRecordType, WorkspaceInput } from '../../API';
import { UserAvailabilityActions } from '../availability/actions';
import { bookingPagesActions } from '../bookingPages';
import { bookingTemplatesActions } from '../bookingTemplates';
import { workspacesActions } from '../workspaces/actions';
import { GET_MAIN_DATA_ERROR_TOAST, authentificationActions } from '../authentification';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { handleServiceError } from '../utils/reduxUtils';
import { usersActions } from '../users';
import { groupBookingPagesActions } from '../groupBookingPages';

function* getMainDataSaga(): SagaIterator<void> {
  try {
    const response: AuthentificationData = yield call(getAuthentification);

    if (response) {
      const {
        adminDataRecord,
        bookingPageRecords,
        isFirstTenantUser,
        licensesRecords,
        bookingTemplateRecords,
        roleRecord,
        tenantRecord,
        userDataRecords,
        groupBookingPages,
      } = response;

      // catching if something important is missed
      const missingData = [];
      if (!adminDataRecord) missingData.push('adminDataRecord');
      if (isFirstTenantUser === null || isFirstTenantUser === undefined) missingData.push('isFirstTenantUser');
      if (!licensesRecords.length) missingData.push('licensesRecords');
      if (!userDataRecords.length) missingData.push('userDataRecords');
      if (!roleRecord) missingData.push('roleRecord');
      if (!tenantRecord) missingData.push('tenantRecord');

      if (missingData.length > 0) {
        throw new Error(`getMainDataSaga(); ${missingData.join(', ')} not found`);
      }

      const userDataProfile: UserDataInputCreatedAt | null | undefined = userDataRecords.find(
        (record) => record.recordType === UserRecordType.PROFILE
      );

      if (userDataProfile && userDataProfile.userSettings) {
        const userId = userDataProfile.userId;
        const tenantId = userDataProfile.tenant;
        const link = userDataProfile.link;
        const workspaceIds = adminDataRecord.workspaceIds
          ? adminDataRecord.workspaceIds.filter((id): id is string => id !== null)
          : [];
        const workspaceId = localStorage.getItem(UserSettingsKeys.WORKSPACE_ID) || workspaceIds[0] || null;
        const userDataWithAvailabilites: UserDataInputCreatedAt[] = userDataRecords.filter(
          (record) => record !== userDataProfile
        );

        const details: UserDetails = {
          loggedRole: adminDataRecord.roleId,
          workspaceIds,
          isFirstTenantUser,
        };

        // saving UserDataCore
        if (workspaceId) {
          yield put(
            authentificationActions.updateUserDataCore({ userId, link, tenantId, workspaceId })
          );
          localStorage.setItem(UserSettingsKeys.TENANT_ID, tenantId);
          localStorage.setItem(UserSettingsKeys.USER_ID, userId);
          localStorage.setItem(UserSettingsKeys.WORKSPACE_ID, workspaceId);
          localStorage.setItem(UserSettingsKeys.LINK, link);
        } else {
          throw new Error('getMainDataSaga(); Workspaces not found');
        }
      
        // Get Users if have a permission READ
        if (roleRecord && roleRecord.users?.includes(PermissionAction.READ)) {
          yield put(usersActions.getUsersRequest())
        }

        // saving UserDetails
        yield put(userSettingsActions.setUserDetails(details)); // TODO: remove it, use userSettings, roles and workspaces

        // saving LoggedRole
        yield put(userSettingsActions.setUserPermissions(roleRecord));

        // saving UserSettings
        yield put(userSettingsActions.getUserSettingsSuccess(userDataProfile.userSettings));

        // saving Statistics
        if (userDataProfile && 'statistics' in userDataProfile && userDataProfile.statistics) {
          yield put(userSettingsActions.setStatistics(userDataProfile.statistics));
        }

        // saving Availabilities
        yield put(UserAvailabilityActions.getUserAvailabilitySuccess(userDataWithAvailabilites));

        // saving BookingPages
        const filteredBookingPages = bookingPageRecords.filter(page => page.workspaceId === workspaceId);
        yield put(bookingPagesActions.getBookingPagesSuccess(filteredBookingPages));

        // saving GroupBookingPages
        const filteredGroupBookingPages = groupBookingPages.filter((page) => page.workspaceId === workspaceId);
        yield put(groupBookingPagesActions.getGroupBookingPagesSuccess(filteredGroupBookingPages));

        // saving BookingTemplates
        const filteredBookingTemplates = bookingTemplateRecords.filter(page => page.workspaceId === workspaceId);
        yield put(bookingTemplatesActions.getBookingTemplatesSuccess(filteredBookingTemplates));

        // saving Workspaces
        if (tenantRecord.workspace) {
          let workspaces: WorkspaceInput[] = [];
          workspaces = tenantRecord.workspace.filter((workspace): workspace is WorkspaceInput => Boolean(workspace));
          yield put(workspacesActions.getWorkspacesSuccess(workspaces));
        }

        // saving Tenant
        yield put(authentificationActions.getTenantSuccess(tenantRecord));

        // saving Licences
        yield put(authentificationActions.getLicenseSuccess(licensesRecords));
      } else {
        throw new Error('getMainDataSaga(); User Settings not found');
      }
    } else {
      throw new Error('getMainDataSaga(); getAuthentification is not responded');
    }

    yield put(globalActions.getMainDataSuccess());
  } catch (error: unknown) {
    yield put(globalActions.getMainDataFail());
    yield call(handleServiceError, error, GET_MAIN_DATA_ERROR_TOAST);
    navigationService.navigateTo(Path.Landing);
  }
}

export function* watchGlobalSaga() {
  yield takeLatest(GlobalActionTypes.GET_MAIN_DATA_REQUEST, getMainDataSaga);
}
