import { UpdateGroupBookingPageInput } from '../../API';
import { APIPostRequest } from '../utils/reduxUtils';
import { GetGroupBookingPageResponse } from './types';

export async function getGroupBookingPages(workspaceId: string) {
  try {
    return (await APIPostRequest('/api/public/getGroupBookingPage', {
      workspaceId,
    })) as GetGroupBookingPageResponse;
  } catch (error: unknown) {
    console.error('Redux getGroupBookingPages service; Error get group booking pages:', error);
    throw error;
  }
}

export async function getGroupBookingPage(id: string) {
  try {
    return (await APIPostRequest('/api/public/getGroupBookingPage', {
      id,
    })) as GetGroupBookingPageResponse;
  } catch (error: unknown) {
    console.error('Redux getGroupBookingPage service; Error get group booking page:', error);
    throw error;
  }
}

export async function upsertGroupBookingPage(groupBookingPage: UpdateGroupBookingPageInput) {
  try {
    await APIPostRequest('/api/public/upsertGroupBookingPage', { groupBookingPage });
  } catch (error: unknown) {
    console.error('Redux upsertGroupBookingPage service; Error upsert group booking page:', error);
    throw error;
  }
}

export async function deleteGroupBookingPage(ids: string[]) {
  try {
    await APIPostRequest('/api/public/deleteGroupBookingPage', { ids });
  } catch (error: unknown) {
    console.error('Redux groupBookingPages service; Error deleting group booking pages:', error);
    throw error;
  }
}
