export const GroupBookingPage = Object.freeze({
  GROUP_TITLE: `Grupo`,
  GROUPS_TITLE: `Grupos`,
  GROUP_PAGE: `Página de grupo`,
  SELECT_MEETING_TYPE: `Selecione um tipo de reunião`,
});

export const GroupBookingPagesToastsNotifications = Object.freeze({
  CLONE_GROUP_PAGES_ERROR_MESSAGE: `Falha na solicitação de clonagem da página de reserva de grupo`,
  CLONE_GROUP_PAGES_SUCCESS_MESSAGE: `Página de reserva de grupo clonada com sucesso`,
  DELETE_GROUP_PAGES_ERROR_MESSAGE: `Falha na solicitação de exclusão da página de reserva de grupo`,
  DELETE_GROUP_PAGES_SUCCESS_MESSAGE: `Página de reserva de grupo excluída com sucesso`,
  GET_GROUP_PAGE_ERROR_MESSAGE: `Não foi possível recuperar a página de reserva de grupo, ela pode ter sido excluída`,
  GET_GROUP_PAGES_ERROR_MESSAGE: `Falha na solicitação de obtenção das páginas de reserva de grupo`,
  SAVE_GROUP_PAGE_ERROR_MESSAGE: `Falha na solicitação de salvamento da página de reserva de grupo`,
  SAVE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo salva com sucesso`,
  ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo ativada com sucesso`,
  DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo desativada com sucesso`,
});

export const EditGroupBookingPage = Object.freeze({
  ADD_DESCRIPTION: `Adicionar descrição`,
  ADMIN_ONLY_TITLE: `Apenas Administrador`,
  BASIC_SETTING_INFO_MESSAGE: `Esteja ciente de que esta página de reserva de grupo é baseada nas páginas de reserva selecionadas e quaisquer alterações feitas nessas páginas de reserva de origem serão refletidas neste link de reserva de grupo.`,
  BASIC_SETTINGS_DESCRIPTION: `Páginas de reserva e nome do grupo`,
  BASIC_SETTINGS_TITLE: `Configurações básicas`,
  BOOKING_PAGES_DESCRIPTION: `Você pode escolher páginas de reserva`,
  BOOKING_PAGES_TITLE: `Páginas de reserva`,
  BRANDING_DESCRIPTION: `Descrição, estilos de marca e exibições`,
  BRANDING_TITLE: `Como deve parecer?`,
  BRANDING_WARNING: `Aviso: Você tem estilos de marca diferentes para as páginas de reserva.`,
  CLONE_TITLE: `Nome da página de grupo clonada`,
  DEACTIVATE_DESCRIPTION: `Tem certeza de que deseja desativar esta página de reserva de grupo?`,
  DEACTIVATE_TITLE: `Desativar página de reserva de grupo`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir esta página de reserva de grupo?`,
  DELETE_TITLE: `Excluir página de reserva de grupo`,
  MEETING_DESCRIPTION_TITLE: `Descrição da reunião`,
  NAME_PLACEHOLDER: `Nome personalizado`,
  NAME_TITLE: `Exibir nome do grupo`,
  USE_BRANDING_TITLE: `Usar configurações de marca de:`,
  VIEW_GROUP_PAGE: `Ver página do grupo`,
  MEETING_DESCRIPTION_TOOLTIP: `O texto da descrição da reunião é exibido apenas na página pública de reservas do grupo, para o convidado.`,
  TITLE: `Página de reserva em grupo`,
  AND: `e`,
  MORE: `mais`,
});
