import { combineReducers } from 'redux';
import { groupBookingPagesActionTypes, GroupBookingPagesAction } from './actions';
import { UpdateGroupBookingPageInput } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { DEFAULT_GROUP_BOOKING_PAGE } from './constants';
import { updateBrandingSettings } from './utils';

const groupBookingPages = (
  state: UpdateGroupBookingPageInput[] = [],
  action: GroupBookingPagesAction | GlobalAction
) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const groupBookingPage = (state = DEFAULT_GROUP_BOOKING_PAGE, action: GroupBookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.SET_GROUP_BOOKING_PAGE:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_SUCCESS:
      return action.payload;
    case groupBookingPagesActionTypes.UPDATE_GROUP_BOOKING_PAGE:
      return { ...state, ...action.payload, ...updateBrandingSettings(state, action.payload) };
    case groupBookingPagesActionTypes.RESET_GROUP_BOOKING_PAGE:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_GROUP_BOOKING_PAGE;
    default:
      return state;
  }
};

const cloneName = (state = '', action: GroupBookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const isEdited = (state: boolean = false, action: GroupBookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.UPDATE_GROUP_BOOKING_PAGE:
      return true;
    case groupBookingPagesActionTypes.RESET_GROUP_BOOKING_PAGE:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action: GroupBookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_REQUEST:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_REQUEST:
      return true;
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_SUCCESS:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_FAIL:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_SUCCESS:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_FAIL:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_SUCCESS:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: GroupBookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_REQUEST:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_REQUEST:
      return null;
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_FAIL:
    case groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_FAIL:
    case groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  groupBookingPages,
  groupBookingPage,
  cloneName,
  isEdited,
  isFetching,
  error,
});
