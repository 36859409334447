import { UpdateGroupBookingPageInput } from '../../API';
import { t } from '../../i18n/i18n';
import { DEFAULT_BOOKING_PAGE_HOW, DEFAULT_STYLE } from '../bookingPages';
import { ToastNotificationOptions } from '../notifications';

export const CLONE_NAME_POSTFIX = ' (copy)';
export const DEFAULT_GROUP_BOOKING_PAGE = {
  id: '',
  workspaceId: '',
  name: t('GroupBookingPage:GROUP_PAGE'),
  active: true,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
} as UpdateGroupBookingPageInput;

export const CLONE_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('GroupBookingPagesToastsNotifications:CLONE_GROUP_PAGES_ERROR_MESSAGE'),
});
export const CLONE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('GroupBookingPagesToastsNotifications:CLONE_GROUP_PAGES_SUCCESS_MESSAGE'),
});
export const DELETE_GROUP_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('GroupBookingPagesToastsNotifications:DELETE_GROUP_PAGES_ERROR_MESSAGE'),
});
export const DELETE_GROUP_PAGES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('GroupBookingPagesToastsNotifications:DELETE_GROUP_PAGES_SUCCESS_MESSAGE'),
});
export const GET_GROUP_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('GroupBookingPagesToastsNotifications:GET_GROUP_PAGES_ERROR_MESSAGE'),
});
export const GET_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('GroupBookingPagesToastsNotifications:GET_GROUP_PAGE_ERROR_MESSAGE'),
});
export const SAVE_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'error',
  autoHideDuration: 6000,
  message: t('GroupBookingPagesToastsNotifications:SAVE_GROUP_PAGE_ERROR_MESSAGE'),
});
export const SAVE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('GroupBookingPagesToastsNotifications:SAVE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
export const ACTIVATE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('GroupBookingPagesToastsNotifications:ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: 'success',
  autoHideDuration: 3000,
  message: t('GroupBookingPagesToastsNotifications:DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
