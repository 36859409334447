import { Button } from 'primereact/button';
import { Modal } from '../../common';
import { ADD_TO_WEBSITE_MODAL, addToWebsiteModalActions, bookingPageSelectors } from '../../../store/bookingPages';
import { useDispatch, useSelector } from 'react-redux';
import labels from './labels';
import { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import {
  ADD_TO_WEBSITE_TYPE,
  BUTTON_POSITION,
  DEFAULT_LABEL,
  ADD_TO_WEBSITE_OPTIONS,
  DEFAULT_BUTTON_POSITION,
} from './constants';
import { getBaseUrl } from '../../../services/utils';

export const BookingPageAddToWebsiteModal = () => {
  const dispatch = useDispatch();
  const lookAndFeel = useSelector(bookingPageSelectors.selectLookAndFeel);
  const link = useSelector(bookingPageSelectors.selectAddToWebsiteLink);

  const [selectedType, setSelectedType] = useState<ADD_TO_WEBSITE_TYPE | null>(null);
  const [linkLabel, setLinkLabel] = useState(DEFAULT_LABEL);
  const [isFloatingButton, setIsFloatingButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(DEFAULT_BUTTON_POSITION);
  const [hidePageDetails, setHidePageDetails] = useState(false);
  // const [hideCookieBar, setHideCookieBar] = useState(false);

  const baseUrl = getBaseUrl();

  const clearState = () => {
    setSelectedType(null);
    setLinkLabel(DEFAULT_LABEL);
    setIsFloatingButton(false);
    setButtonPosition(DEFAULT_BUTTON_POSITION);
    setHidePageDetails(false);
    // setHideCookieBar(false);
  };
  const handleClose = () => {
    clearState();
    dispatch(addToWebsiteModalActions.closeModal());
  };

  const handleBack = () => {
    clearState();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getEmbedText());
  };

  const getIsEmbed = () => selectedType === ADD_TO_WEBSITE_TYPE.EMBED;
  const getIsPopup = () => selectedType === ADD_TO_WEBSITE_TYPE.POPUP;

  const getTitleByType = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return labels.embedTitle;
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return labels.buttonToPopup;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return labels.linkTitle;
      default:
        return '';
    }
  };

  const getDescriptionByType = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return labels.copyCodeDescEmbed;
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return labels.copyCodeDescPopup;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return labels.copyCodeDescLink;
      default:
        return '';
    }
  };

  const getLink = () => {
    return `${baseUrl}${link}?${[
      'iframe=true',
      // `hideCookie=${hideCookieBar}`,
      `hidePageDetails=${hidePageDetails}`,
      `hideHeader=${getIsEmbed()}`,
    ].join('&')}`;
  };

  const getEmbedTypeData = () => {
    return `<div id="sumo1-widget" data-url=${getLink()} id="sumo1-widget" style="min-width:320px;height:700px;"></div>`;
  };

  const getFloatingButtonTypeData = () => {
    return `<script type="text/javascript">window.onload = function() { Sumo1Widgets.renderFloatingButton({url:'${getLink()}', background:'${
      lookAndFeel?.siteColorButtons
    }', text:'${linkLabel}', position: '${buttonPosition}'}); }</script>`;
  };

  const getButtonTypeData = () => {
    return `<button data-url=${getLink()} id="sumo1-widget" style="color:#ffffff;background:${
      lookAndFeel?.siteColorButtons
    };border-color: ${lookAndFeel?.siteColorButtons};">${linkLabel}</button>`;
  };

  const getLinkTypeData = () => {
    return `<a id="sumo1-widget" data-url=${getLink()}>${linkLabel}</a>`;
  };

  const getAdditionalData = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return getEmbedTypeData();
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return isFloatingButton ? getFloatingButtonTypeData() : getButtonTypeData();
      case ADD_TO_WEBSITE_TYPE.LINK:
        return getLinkTypeData();
      default:
        return '';
    }
  };

  const getEmbedText = () => {
    return `<!-- SUMO1 ${getTitleByType()} widget begin -->
      <link href="${baseUrl}/addToWebsite/addToWebsite.css" rel="stylesheet">
      <script src="${baseUrl}/addToWebsite/addToWebsite.js" type="text/javascript" id="sumo1-widget" async></script>
      ${getAdditionalData()}
      <!-- SUMO1 ${getTitleByType()} widget end -->`;
  };

  return (
    <Modal.Container name={ADD_TO_WEBSITE_MODAL} style={{ width: '100%', maxWidth: '900px' }}>
      <Modal.Header>
        {!selectedType ? (
          <div className="font-semibold text-center">{labels.title}</div>
        ) : (
          <div className="font-semibold ml-2 -mb-3">{getTitleByType()}</div>
        )}
      </Modal.Header>
      {!selectedType ? (
        <div className="grid ">
          {ADD_TO_WEBSITE_OPTIONS.map((item, index) => (
            <div className="col-12 md:col-4" key={index}>
              <div
                className="h-full border-1 border-noround card cursor-pointer flex flex-column align-items-center hover:bg-gray-50 shadow-none surface-border mx-2"
                onClick={() => setSelectedType(item.type)}
              >
                <div className="text-xl mb-2">{item.label}</div>
                <div>
                  <i className={`pi ${item.icon} text-6xl`}></i>
                </div>
                <div className="max-w-16rem mt-2">{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid">
          <div className={`col-12 ${getIsEmbed() ? '' : 'md:col-8'} flex flex-column px-3`}>
            <div className="font-semibold text-lg">{labels.copyCodeTitle}</div>
            <div className="py-2">
              {getDescriptionByType()}
            </div>
            <InputTextarea readOnly={true} rows={9} autoResize value={getEmbedText()} />
          </div>
          {!getIsEmbed() && (
            <div className="col-12 md:col-4 flex flex-column px-3">
              <div className="font-semibold text-lg">{labels.customizeTitle}</div>
              <div className="max-w-20rem py-2">{labels.customizeDesc}</div>
              <div className="flex flex-column p-3 border-1 surface-border h-full">
                {/* {selectedType !== ADD_TO_WEBSITE_TYPE.EMBED && ( */}
                <div className="py-2">
                  <label htmlFor="widgetLabel">
                    {getTitleByType()} {labels.label}
                  </label>
                  <InputText
                    id="widgetLabel"
                    className="w-full"
                    value={linkLabel}
                    onChange={(e) => setLinkLabel(e.target.value)}
                  />
                </div>
                {/* )} */}
                {getIsPopup() && (
                  <div className="py-2">
                    <Checkbox
                      checked={isFloatingButton}
                      inputId="floatingButton"
                      className="mr-2"
                      onChange={(e) => setIsFloatingButton(!!e.target.checked)}
                    />
                    <label htmlFor="floatingButton">{labels.floatingButtonDesc}</label>
                    <div className="py-1 ml-5 flex flex-column">
                      <label htmlFor="buttonLocation">{labels.location}</label>
                      <Dropdown
                        inputId="buttonLocation"
                        className="w-full"
                        disabled={!isFloatingButton}
                        value={buttonPosition}
                        options={BUTTON_POSITION}
                        onChange={(e) => setButtonPosition(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {/* TODO allow option when new Event Message field (on Booking Page lvl) will be added */}
                {/* <div className="py-2">
                  <Checkbox
                    checked={hidePageDetails}
                    inputId="hidePageDetails"
                    className="mr-2"
                    onChange={(e) => setHidePageDetails(!!e.target.checked)}
                  />
                  <label htmlFor="hidePageDetails">{labels.hidePageDetails}</label>
                </div> */}
                {/* TODO allow option when cookie banner will be used on PBP */}
                {/* <div className="py-2">
                  <Checkbox
                    checked={hideCookieBar}
                    inputId="hideCookieBar"
                    className="mr-2"
                    onChange={(e) => setHideCookieBar(!!e.target.checked)}
                  />
                  <label htmlFor="hideCookieBar">{labels.hideCookieBanner}</label>
                </div> */}
              </div>
            </div>
          )}
        </div>
      )}
      <Modal.Buttons>
        {!selectedType ? (
          <div className="flex ml-2">
            <Button outlined onClick={handleClose} label={labels.close} className="w-8rem" />
          </div>
        ) : (
          <div className="flex justify-content-between  ml-2">
            <Button outlined onClick={handleBack} label={labels.back} className="w-8rem" />
            <div>
              <Button text onClick={handleClose} label={labels.close} />
              <Button label={labels.copyCodeTitle} className="w-8rem" onClick={handleCopy} />
            </div>
          </div>
        )}
      </Modal.Buttons>
    </Modal.Container>
  );
};
