import dayjs from 'dayjs';
import { UpdateBookingPageInput, TimeFormat, TeamOrUserInput, IntegrationType, BookingPageStyleInput } from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';
import { FILE_COPY_POSTFIX } from './constants';
import { getBaseUrl, isObjectDeepEqual } from '../../services/utils';
import { Path } from '../../routing';
import { UserAdminDataFullRecord } from '../users/typings';
import { TeamRecord } from '../teams';
import { BookingPageWhoOption } from './types';

export const generatePublicFileKey = (filePath: string | null | undefined) =>
  filePath ? filePath.substring(filePath.lastIndexOf('public/') + 7) : '';

export const generatePublicFileKeyCopy = (filePath: string | null | undefined) => {
  const fileName = generatePublicFileKey(filePath);
  if (!fileName) {
    return '';
  }
  return fileName.indexOf('.') ? fileName.replace('.', FILE_COPY_POSTFIX + '.') : fileName + FILE_COPY_POSTFIX;
};

export const assignTime = (timeZoneList: timeZoneItem[], UTC: number, timeFormat: TimeFormat | null | undefined) =>
  timeZoneList.map((timeZone) => ({
    ...timeZone,
    value: timeZone.timeZone,
    time: dayjs(UTC)
      .tz(timeZone.timeZone)
      .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
  }));

export const generateShortUrlRequest = (bookingPage: UpdateBookingPageInput, id: string) => ({
  bookingPage: { ...bookingPage, id },
  url: getBaseUrl() + Path.PublicBookingPage.replace(':bookingPageId', '') + id,
});

export const getWhoData = (
  users: UserAdminDataFullRecord[],
  teams: TeamRecord[],
  records: Array<TeamOrUserInput | null>
) => {
  const hostData: BookingPageWhoOption[] = [];
  for (const record of records) {
    if (record?.userId) {
      const user = users.find((user) => user.userId === record?.userId);
      if (user) {
        hostData.push({
          value: user.userId!,
          label: user.fullName || user.email,
          isTeam: false,
          phoneNumber: !!user.phoneNumber,
          integrationTypes: user.integrationTypes || [],
          defaultVideoIntegration: user.defaultVideoIntegration,
        });
      }
    } else {
      const team = teams.find((team) => team.id === record?.teamId);
      if (team) {
        let phoneNumber = false;
        const integrationTypes = new Set<IntegrationType>();
        team.members?.forEach((member) => {
          const user = users.find((user) => user.userId === member?.userId);
          if (user) {
            phoneNumber = phoneNumber || !!user.phoneNumber;
            user.integrationTypes?.forEach((integration) => integrationTypes.add(integration));
          }
        });
        hostData.push({
          value: team.id!,
          label: team.name || '',
          isTeam: true,
          phoneNumber,
          integrationTypes: Array.from(integrationTypes.values()),
        });
      }
    }
  }
  return hostData;
};

export const isStylesEqual = (
  style1: BookingPageStyleInput | null | undefined,
  style2: BookingPageStyleInput | null | undefined
) => {
  return (
    (style1?.avatar || '') == (style2?.avatar || '') &&
    (style1?.backgroundImage || '') == (style2?.backgroundImage || '') &&
    (style1?.opacity || 0) == (style2?.opacity || 0) &&
    (style1?.css || '') == (style2?.css || '') &&
    (style1?.footer || '') == (style2?.footer || '') &&
    isObjectDeepEqual(style1?.lookAndFeel, style2?.lookAndFeel)
  );
};
