import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { InputTextarea } from 'primereact/inputtextarea';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../../store/groupBookingPages';
import { Dropdown } from 'primereact/dropdown';
import labels from './labels';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';

export const GroupBookingPageHowStep = () => {
  const dispatch = useDispatch();
  const groupBookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);
  const groupBookingPageOptions = useSelector(groupBookingPagesSelectors.selectGroupBookingPageOptions);
  const brandingBookingPagId = useSelector(groupBookingPagesSelectors.selectBrandingBookingPagId);
  const isEqualsStyles = useSelector(groupBookingPagesSelectors.selectIsBookingPageStyleEquals);

  const handleDescriptionChange = (id: string, description: string) => {
    const bookingPages = groupBookingPages.map((page) =>
      page?.bookingPageId === id ? { ...page, description } : page
    );
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages }));
  };

  const handleStyleFromChange = (id: string) => {
    const bookingPage = bookingPagesById[id];
    dispatch(
      groupBookingPagesActions.updateGroupBookingPage({
        brandingBookingPageId: id,
        style: bookingPage.style,
        labels: bookingPage.labels,
      })
    );
  };

  return (
    <div className="flex flex-column text-heavy-100 p-fluid pb-2">
      {!isEqualsStyles && (
        <>
          <div className="w-12 flex align-items-center p-2 mb-3 border-round" style={{ background: '#e7f4fd' }}>
            <span className="pi pi-info-circle mr-2"></span>
            {labels.brandingWarning}
          </div>
          <div className="flex flex-column w-12 lg:w-6 pr-4">
            <div>{labels.useBrandingTitle}</div>
            <Dropdown
              options={groupBookingPageOptions}
              value={brandingBookingPagId}
              onChange={(e) => handleStyleFromChange(e.target.value)}
            />
          </div>
          <div className="border-top-1 border-gray-200 my-4 -mx-3" />
        </>
      )}

      <div className="font-semibold mb-3">{labels.meetingDescTitle}</div>
      {!!groupBookingPages.length && (
        <div className="grid -mb-2">
          {groupBookingPages.map((record, index) => {
            const page = bookingPagesById[record?.bookingPageId || ''];
            return (
              <div
                key={page.id}
                className={`col-12 lg:col-6 flex flex-column ${index % 2 === 0 ? 'lg:pr-4' : 'lg:pl-4'}`}
              >
                <div className="flex align-items-center gap-2">
                  <div className="w-1rem h-1rem border-round" style={{ backgroundColor: page.what?.color || '' }} />
                  <div>{page.what?.customName}</div>
                </div>
                <InputTextarea
                  value={record?.description || ''}
                  onChange={(e) => handleDescriptionChange(page.id, e.target.value)}
                  placeholder={labels.addDescription}
                  autoResize
                  className="mt-2"
                  rows={4}
                  maxLength={MAX_LENGTH_DESCRIPTION}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
