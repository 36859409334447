export const GroupBookingPage = Object.freeze({
  GROUP_TITLE: `Group`,
  GROUPS_TITLE: `Groups`,
  GROUP_PAGE: `Group Page`,
  SELECT_MEETING_TYPE: `Select a meeting type`,
});

export const GroupBookingPagesToastsNotifications = Object.freeze({
  CLONE_GROUP_PAGES_ERROR_MESSAGE: `Clone group booking page request fail`,
  CLONE_GROUP_PAGES_SUCCESS_MESSAGE: `Group booking page successfully cloned`,
  DELETE_GROUP_PAGES_ERROR_MESSAGE: `Delete group booking page request fail`,
  DELETE_GROUP_PAGES_SUCCESS_MESSAGE: `Group Booking page successfully deleted`,
  GET_GROUP_PAGE_ERROR_MESSAGE: `Could not retrieve group booking page, it could be deleted`,
  GET_GROUP_PAGES_ERROR_MESSAGE: `Get group booking pages request fail`,
  SAVE_GROUP_PAGE_ERROR_MESSAGE: `Save group booking page request fail`,
  SAVE_GROUP_PAGE_SUCCESS_MESSAGE: `Group booking page successfully saved`,
  ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Group booking page successfully activated`,
  DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: 'Group booking page successfully deactivated',
});

export const EditGroupBookingPage = Object.freeze({
  ADD_DESCRIPTION: `Add description`,
  ADMIN_ONLY_TITLE: `Admin Only`,
  BASIC_SETTING_INFO_MESSAGE: `Be aware this Group Booking Page is based on the booking pages selected and any changes to these source booking pages will be reflected in this group booking link.`,
  BASIC_SETTINGS_DESCRIPTION: `Booking pages and group name`,
  BASIC_SETTINGS_TITLE: `Basic Settings`,
  BOOKING_PAGES_DESCRIPTION: `You may choose booking pages`,
  BOOKING_PAGES_TITLE: `Booking Pages`,
  BRANDING_DESCRIPTION: `Description, branding styles and displays`,
  BRANDING_TITLE: `How should it look?`,
  BRANDING_WARNING: `Warning: You have different branding styles for booking pages.`,
  CLONE_TITLE: `Cloned group page name`,
  DEACTIVATE_DESCRIPTION: `Are you sure you would like to deactivate `,
  DEACTIVATE_TITLE: `Deactivate Group Booking Page`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete `,
  DELETE_TITLE: `Delete Group Booking Page`,
  MEETING_DESCRIPTION_TITLE: `Meeting Description`,
  NAME_PLACEHOLDER: `Custom name`,
  NAME_TITLE: `Display Group Name`,
  USE_BRANDING_TITLE: `Use branding settings from:`,
  VIEW_GROUP_PAGE: `View Group Page`,
  MEETING_DESCRIPTION_TOOLTIP: `The Meeting Description text is displayed on the Group Public Booking Page only, for the Invitee.`,
  NEW_GROUP_BOOKING_PAGE: `New Group Booking Page`,
  TITLE: `Group Booking Page`,
  AND: `and`,
  MORE: `more`
});
