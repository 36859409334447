import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { LocationType } from '../../../API';
import { formatEventTime } from '../../../services/DateService';
import { eventActions, eventSelectors, EventSteps } from '../../../store/publicBookingPage';
import { Label } from '../../common';
import labels from './labels';
import { SelectItem } from 'primereact/selectitem';

export const PublicBookingPageMeetingDetails = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const isMobile = useSelector(eventSelectors.selectIsMobile);
  const event = useSelector(eventSelectors.selectEvent);
  const timezone = useSelector(eventSelectors.selectTimeZone);
  const is12Hour = useSelector(eventSelectors.selectIs12Hour);
  const hostIsTeam = useSelector(eventSelectors.selectHostIsTeam);
  const hostName = useSelector(eventSelectors.selectHostsNames);
  const hostPhone = useSelector(eventSelectors.selectHostFullPhone);
  const detailsLabel = useSelector(eventSelectors.selectDetailsLabel);
  const subHeaderStyle = useSelector(eventSelectors.selectSubHeaderStyle);
  const mainTextStyle = useSelector(eventSelectors.selectMainTextStyle);
  const outlinedButtonStyle = useSelector(eventSelectors.selectOutlinedButtonStyle);
  const locationType = useSelector(eventSelectors.selectLocationType);
  const locationTypeLabel = useSelector(eventSelectors.selectLocationTypeLabel);
  const locationTypeOptions = useSelector(eventSelectors.selectLocationTypeOptions);
  const isLocationTypeEdit = useSelector(eventSelectors.selectIsLocationTypeEdit);
  const locationSettings = useSelector(eventSelectors.selectLocationSettings);
  const locationOptions = useSelector(eventSelectors.selectLocationOptions);
  const customPhone = useSelector(eventSelectors.selectCustomPhone);
  const customAddress = useSelector(eventSelectors.selectCustomAddress);
  const showLocationInput = useSelector(eventSelectors.selectShowLocationInput);
  const showHostPhone = useSelector(eventSelectors.selectShowHostPhone);
  const showCustomPhone = useSelector(eventSelectors.selectShowCustomPhone);
  const showCustomAddress = useSelector(eventSelectors.selectShowCustomAddress);
  const showLocationSettings = useSelector(eventSelectors.selectShowLocationSettings);
  const showDateTimeEditButton = useSelector(eventSelectors.selectShowDateTimeEditButton);
  const isBookedStep = useSelector(eventSelectors.selectIsBookedStep);

  const handleLocationTypeChange = (type: LocationType) => {
    dispatch(eventActions.updateLocation({ type }));
    dispatch(eventActions.getAgendaRequest());
    dispatch(eventActions.setEventStep(EventSteps.WHEN));
  };

  const handleLocationSettingsChange = (settings: string) => {
    dispatch(eventActions.updateLocation({ settings }));
  };

  const handleDetailLabelChange = (detailsLabel: string) => {
    dispatch(eventActions.setPreviewBookingPageHow({ detailsLabel }));
  };

  const handleEdit = () => {
    dispatch(eventActions.setEventStep(EventSteps.WHEN));
    if (isBookedStep) {
      // re-request slots for reschedule
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const renderSubHeaderLabel = (value: string, onChange: (value: string) => void) => (
    <Label
      editClassName="hover:text-primary"
      textClassName="text-xl font-semibold"
      textStyle={subHeaderStyle}
      editable={isEditable}
      value={value}
      onBlurUpdate={onChange}
    />
  );

  const locationTypeTemplate = (option: SelectItem, smallSize = false) => (
    <div className="flex align-items-center">
      {option.value === LocationType.VIDEO_CONFERENCE ? (
        <i className={`pi pi-video ${smallSize ? '' : 'text-xl'}`} />
      ) : option.value === LocationType.PHONE_CALL ? (
        <i className={`pi pi-phone ${smallSize ? '' : 'text-xl'}`} />
      ) : option.value === LocationType.IN_PERSONAL ? (
        <i className={`pi pi-home ${smallSize ? '' : 'text-xl'}`} />
      ) : (
        <></>
      )}
      <div className="ml-3">{option.label}</div>
    </div>
  );
  const locationTypeOptionTemplate = (option: SelectItem) => locationTypeTemplate(option);
  const locationTypeValueTemplate = (option: SelectItem) => locationTypeTemplate(option, true);

  return (
    <>
      <div className="pl-3 ml-1">{renderSubHeaderLabel(detailsLabel, handleDetailLabelChange)}</div>
      <div className="card mt-1 mb-0 border-1 surface-border shadow-none">
        <div className="-my-1" style={mainTextStyle}>
          {hostName && (
            <>
              <div className="flex">
                <i className={`pi ${hostIsTeam ? 'pi-users' : 'pi-user'} mt-1 mr-3`}></i>
                <span className="mr-2">{labels.host}</span>
                <span>{hostName}</span>
              </div>
              <Divider />
            </>
          )}

          <div className="flex align-items-center gap-3">
            {isLocationTypeEdit ? (
              <Dropdown
                className="border-none -my-2 -ml-2"
                value={locationType}
                options={locationTypeOptions}
                itemTemplate={locationTypeOptionTemplate}
                valueTemplate={locationTypeValueTemplate}
                onChange={(e) => handleLocationTypeChange(e.value)}
              />
            ) : (
              locationTypeValueTemplate({ label: locationTypeLabel, value: locationType })
            )}
            {showLocationInput && (
              <Dropdown
                className="border-none -my-2"
                value={locationSettings}
                options={locationOptions}
                onChange={(e) => handleLocationSettingsChange(e.value)}
              />
            )}
            {showHostPhone && <div>{hostPhone}</div>}
            {showCustomPhone && <div>{customPhone}</div>}
            {showCustomAddress && <div>{customAddress}</div>}
            {showLocationSettings && <div>{locationSettings}</div>}
          </div>

          {event.startTime && (
            <>
              <Divider />
              <div className="flex justify-content-between">
                <div className="flex">
                  <i className="pi pi-calendar mt-1 mr-3"></i>
                  <div>
                    <div>{formatEventTime(event.startTime, event.endTime, timezone, is12Hour)}</div>
                    <div className="text-sm opacity-50">{timezone}</div>
                  </div>
                </div>
                {showDateTimeEditButton && (
                  <div className="ml-2">
                    {isMobile ? (
                      <Button style={outlinedButtonStyle} label={labels.edit} outlined onClick={handleEdit} />
                    ) : (
                      <Button
                        style={outlinedButtonStyle}
                        className="w-2rem h-2rem p-0"
                        icon="pi pi-pencil"
                        outlined
                        tooltip={labels.edit}
                        tooltipOptions={{ position: 'top' }}
                        onClick={handleEdit}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
