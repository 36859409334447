import { memo, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Path } from '../../../routing';
import {
  addToWebsiteModalActions,
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
  LOCATION_TYPE_ICONS,
} from '../../../store/bookingPages';
import labels from './labels';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { UpdateBookingPageInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { getBaseUrl } from '../../../services/utils';

interface IBookingPageCard {
  bookingPage: UpdateBookingPageInput;
  handleCheckboxChange: (id: string, checked: boolean | undefined) => void;
}

export const BookingPageCard = memo(({ bookingPage, handleCheckboxChange }: IBookingPageCard) => {
  const { id, shortLink, what } = bookingPage;
  const isActive = bookingPage.what?.isActive;
  const dispatch = useDispatch();
  const menu = useRef<Menu>(null);
  const selectedBookingPages = useSelector(bookingPageSelectors.selectSelectedBookingPages);

  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked(bookingPage));
  const isBookingPageCreate = useSelector(bookingPageSelectors.selectIsBookingPageCreate(bookingPage));
  const isBookingPageEdit = useSelector(bookingPageSelectors.selectIsBookingPageEdit(bookingPage));
  const isBookingPageDelete = useSelector(bookingPageSelectors.selectIsBookingPageDelete(bookingPage));

  const hostsInfo = useSelector(bookingPageSelectors.selectHostsInfo(bookingPage));

  const selected = selectedBookingPages.includes(id || '');
  const [linkCopied, setLinkCopied] = useState(false);

  // Reset linkCopied after 3 seconds
  useEffect(() => {
    linkCopied && setTimeout(() => setLinkCopied(false), 3000);
  }, [linkCopied]);

  const handleEdit = () => {
    navigationService.navigateTo(Path.EditBookingPage.replace(':bookingPageId', id));
  };

  const handleActivate = () => {
    dispatch(
      bookingPagesActions.activateBookingPageRequest({
        ...bookingPage,
        what: { ...bookingPage.what, isActive: !isActive },
      })
    );
  };

  const handleClone = () => {
    dispatch(bookingPagesActions.setBookingPage(bookingPage));
    dispatch<any>(bookingPagesActions.cloneBookingPageThunk()); // TODO: any
  };

  const handleDelete = () => {
    dispatch(bookingPagesActions.selectBookingPage(id));
    dispatch(deleteBookingPagesModalActions.openModal());
  };

  const handleCopyLink = () => {
    const bookingPageUrl = shortLink ? shortLink : getBaseUrl() + Path.PublicBookingPage.replace(':bookingPageId', id);
    navigator.clipboard.writeText(bookingPageUrl);
    setLinkCopied(true);
  };

  const handleChecked = (checked: boolean) => {
    if (id) {
      handleCheckboxChange(id, checked);
    }
  };

  const handleAddToWebsite = () => {
    dispatch(bookingPagesActions.setBookingPage(bookingPage));
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicBookingPage.replace(':bookingPageId', id)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  const menuItems = [
    {
      label: labels.edit,
      icon: 'pi pi-fw pi-pencil',
      command: handleEdit,
      visible: isBookingPageEdit,
    },
    {
      label: isActive ? labels.deactivate : labels.activate,
      icon: isActive ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleActivate,
      visible: isBookingPageEdit,
    },
    {
      label: labels.clone,
      icon: 'pi pi-fw pi-clone',
      command: handleClone,
      visible: isBookingPageCreate,
    },
    {
      label: labels.delete,
      icon: 'pi pi-fw pi-trash',
      command: handleDelete,
      visible: isBookingPageDelete,
    },
  ];

  return (
    <div
      className={`h-full border-1 border-round surface-border cursor-pointer hover:bg-gray-50 ${
        isActive ? '' : 'bg-gray-100 text-gray-500'
      }`}
      onClick={handleEdit}
    >
      <div
        className={`flex flex-column card shadow-none bg-transparent`}
        style={{
          borderLeft: `6px solid ${isActive ? what?.color : '#9E9E9E'}`,
          margin: '-1px',
          height: 'calc(100% + 2px)',
        }}
      >
        <div className="flex mb-2">
          {isBookingPageDelete && (
            <div className="mr-4 flex" onClick={(e) => e.stopPropagation()}>
              <Checkbox checked={selected} onChange={(e) => handleChecked(!!e.target.checked)} />
            </div>
          )}
          <div className='mr-auto h-fit bg-gray-200 px-2 border-round'>{bookingPage.displayId}</div>
          {/* TODO: uncomment when autoNumber is created */}
          {/* <div className='mr-auto h-fit bg-gray-200 px-2 border-round'>{bookingPage.autoNumber}</div> */}
          {(isBookingPageCreate || isBookingPageEdit || isBookingPageDelete) && (
            <div className="static sm:relative flex-container -mt-2 -mr-2 mb-2" onClick={(e) => e.stopPropagation()}>
              <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
              <Button rounded text icon="pi pi-cog text-lg" onClick={(event) => menu.current?.toggle(event)} />
            </div>
          )}
        </div>

        <div className=" flex flex-row align-items-end">
          <div className="text-xl font-medium overflow-hidden text-overflow-ellipsis white-space-nowrap">
            {bookingPage.what?.customName}
            {/* {bookingTemplateId ? bookingTemplateNamesById[bookingTemplateId] : bookingPage.what?.customName} */}
          </div>
          {!!bookingPage.where?.locationTypes?.length && (
            <div className="min-w-max">
              {bookingPage.where?.locationTypes?.map(
                (locationType) =>
                  locationType && <i key={locationType} className={`${LOCATION_TYPE_ICONS[locationType]} ml-2`} />
              )}
            </div>
          )}
        </div>

        <div className="flex flex-column mt-auto mt-1 mb-2" style={{ minHeight: '3rem' }}>
          <div className="overflow-hidden text-overflow-ellipsis white-space-nowrap font-light">{hostsInfo}</div>
          {isBookingPageLocked && (
            <div className="locked bg-yellow-100 border-round p-1 px-2 w-fit">
              <i className="pi pi-lock mr-2" />
              {labels.locked}
            </div>
          )}
        </div>

        <div className="flex mt-auto -mb-3 -ml-3">
          {isActive && (
            <>
              <Button
                icon={linkCopied ? 'pi pi-check' : 'pi pi-copy'}
                label={linkCopied ? labels.copiedLink : labels.copyLink}
                text
                onClick={(e) => {
                  e.stopPropagation(); // to prevent card click during click on inner elements
                  handleCopyLink();
                }}
              />
              <Button
                icon="pi pi-globe"
                label={labels.addToSite}
                text
                onClick={(e) => {
                  e.stopPropagation(); // to prevent card click during click on inner elements
                  handleAddToWebsite();
                }}
              />
            </>
          )}
          {!isActive && isBookingPageEdit && (
            <>
              <Button
                label={labels.activate}
                text
                onClick={(e) => {
                  e.stopPropagation(); // to prevent card click during click on inner elements
                  handleActivate();
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
});

BookingPageCard.displayName = 'BookingPageCard';
