import { t } from '../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  save: t('Common:SAVE'),
  active: t('EditBookingPage:ACTIVE'),
  edit: t('Common:EDIT'),
  viewGroupPage: t('EditGroupBookingPage:VIEW_GROUP_PAGE'),
  copyLink: t('EditBookingPage:COPY_LINK'),
  addToWebsite: t('EditBookingPage:ADD_TO_WEBSITE'),
  basicSettings: t('EditGroupBookingPage:BASIC_SETTINGS_TITLE'),
  basicSettingsDesc: t('EditGroupBookingPage:BASIC_SETTINGS_DESCRIPTION'),
  branding: t('EditGroupBookingPage:BRANDING_TITLE'),
  brandingDesc: t('EditGroupBookingPage:BRANDING_DESCRIPTION'),
  newGroupBookingPage: t('EditGroupBookingPage:NEW_GROUP_BOOKING_PAGE'),
  backToList: t('EditBookingPage:BACK_TO_LIST'),
  title: t('EditGroupBookingPage:TITLE'),
};
