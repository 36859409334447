export enum Path {
  Account = '/account',
  Authorise = '/authorise', // redirect to Landing
  Availability = '/availability',
  Billing = '/billing',
  BookingPages = '/booking-pages',
  CalendarConnections = '/calendar-connections',
  ConnectIntegration = '/connect-integration',
  EditBookingPage = '/edit-booking-page/:bookingPageId',
  EditBookingTemplate = '/edit-booking-template/:bookingTemplateId',
  EditGroupBookingPage = '/edit-group-booking-page/:groupBookingPageId',
  EditWorkspace = '/edit-workspace',
  ErrorPage = '/error',
  Event = '/event/:eventId',
  Integration = '/integration',
  Login = 'login',
  Landing = '/',
  BookingTemplates = '/booking-templates',
  MicrosoftAuthRedirect = 'microsoft-auth-redirect',
  MyLink = 'my-link',
  MyLogin = 'my-login',
  MyRole = 'my-role',
  OPSConsole = '/opsconsole',
  OPSConsoleAccounts = '/opsconsole/accounts',
  OPSConsoleAccount = '/opsconsole/account/:accountId',
  OPSConsoleBilling = '/opsconsole/billing',
  OPSConsoleUsers = '/opsconsole/users',
  OPSConsoleUserDetails = '/opsconsole/user/:tenantId/:email',
  OPSConsoleOrgs = '/opsconsole/orgs',
  OPSConsoleOrgDetails = '/opsconsole/org/:orgId',
  OPSConsoleStaff = '/opsconsole/staff',
  OPSConsoleStaffDetails = '/opsconsole/staff/details',
  OPSConsoleViewAsUser = '/opsconsole/view-as-user/:tenantId/:userId',
  PublicBookingPage = '/book/:bookingPageId',
  PublicGroupBookingPage = '/group/:groupBookingPageId',
  QuickSetup = '/quick-setup',
  Register = '/register', // redirect to Landing
  Roles = '/roles',
  BookedMeetings = '/booked-meetings',
  Tests = '/tests',
  BillingTests = '/billing-tests',
  LicenseExpired = '/license-expired',
  UnknownPath = '*',
  Users = '/users',
  Workspaces = '/workspaces',
  Teams = '/teams',
}
