import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConfirmationModal, Preloader, SectionTitle } from '../../components/common';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import labels from './labels';
import { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { GroupBookingPageBasicSettings } from '../../components/groupBookingPages/groupBookingPageBasicSettings/GroupBookingPageBasicSettings';
import { GroupBookingPageHowStep } from '../../components/groupBookingPages/groupBookingPageHowStep/GroupBookingPageHowStep';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { addToWebsiteModalActions, bookingPagesActions } from '../../store/bookingPages';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages';
import { getBaseUrl } from '../../services/utils';

export const EditGroupBookingPage = () => {
  const { groupBookingPageId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(groupBookingPagesSelectors.selectIsFetching);
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const name = useSelector(groupBookingPagesSelectors.selectGroupBookingPageName);
  const isEdited = useSelector(groupBookingPagesSelectors.selectIsEdited);
  const isGroupPageValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageValid);
  const basicInfo = useSelector(groupBookingPagesSelectors.selectBasicInfo)

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [accordionIndexes, setAccordionIndexes] = useState<number | null>(null);

  const headerTemplate = (title: string, description: string, showButton: boolean) => (
    <>
      {showButton && <div className="absolute right-0 mr-2 h-1rem flex align-items-center">{renderSaveCancelButtons()}</div>}
      <div className="font-normal h-1rem flex flex-column justify-content-center">
        <div>{title}</div>
        {description && <div className="mt-1 text-sm text-gray-500">{description}</div>}
      </div>
    </>
  );

  useEffect(() => {
    setAccordionIndexes(null);
    dispatch(
      groupBookingPagesActions.getGroupBookingPageRequest({
        id: !groupBookingPageId || groupBookingPageId === 'new' ? '' : groupBookingPageId,
        isInitialVisit: false,
      })
    );
  }, []);

  useEffect(() => {
    if (groupBookingPage.workspaceId && !isFetching) {
      setAccordionIndexes(0);
    }
  }, [groupBookingPage.workspaceId, isFetching]);

  const handleCopyLink = () => {
    const bookingPageUrl = groupBookingPage.shortLink
      ? groupBookingPage.shortLink
      : getBaseUrl() + Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPage.id);
    navigator.clipboard.writeText(bookingPageUrl);
  };

  const handleAddToWebsite = () => {
    dispatch(
      bookingPagesActions.setAddToWebsiteLink(
        Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPage.id)
      )
    );
    dispatch(addToWebsiteModalActions.openModal());
  };

  const handleBack = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // for no close the accordion

    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmCancel = () => {
    navigationService.navigateTo(Path.BookingPages);
    dispatch(groupBookingPagesActions.resetGroupBookingPage());
  };

  const handleSave = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // for no close the accordion
    if(!isGroupPageValid){
      setAccordionIndexes(0);
      return
    }
    dispatch(groupBookingPagesActions.upsertGroupBookingPageRequest());
  };

  const renderSaveCancelButtons = () => (
    <>
      <Button className="ml-2 bg-white" label={labels.cancel} outlined onClick={handleBack} />
      <Button className="ml-2" label={labels.save} onClick={handleSave} />
    </>
  );

  return (
    <div>
      {isFetching && <Preloader />}
      <BookingPageAddToWebsiteModal />

      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        cancelButtonProps={{ className: 'w-auto mr-0' }}
        confirmButtonProps={{ className: 'w-auto ml-4 mr-0' }}
        onConfirm={handleConfirmCancel}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle id="editBookingPageTitle" icon="pi-folder" title={labels.title} />
        <div className="flex align-items-center -my-2">
          <div className="p-accordion-header-text">
            <Button
              text
              icon="pi pi-copy"
              label={labels.copyLink}
              onClick={handleCopyLink}
              disabled={!groupBookingPage.id || !groupBookingPage.active}
            />
            <Button
              text
              label={labels.addToWebsite}
              onClick={handleAddToWebsite}
              disabled={!groupBookingPage.id || !groupBookingPage.active}
            />
            {accordionIndexes === null &&
              (isEdited ? (
                renderSaveCancelButtons()
              ) : (
                <Button icon="pi pi-chevron-left" label={labels.backToList} onClick={handleConfirmCancel} />
              ))}
          </div>
        </div>
      </div>

      <div className="text-xl pl-1 ml-3 mb-2 mt-4">
        {groupBookingPage.id ? labels.edit + ' "' + name + '"' : labels.newGroupBookingPage}
      </div>

      <Accordion
        className="sumo-card border-none"
        activeIndex={accordionIndexes}
        onTabChange={(e) => (e.index === null || typeof e.index === 'number') && setAccordionIndexes(e.index)}
      >
        <AccordionTab
          header={headerTemplate(labels.basicSettings, basicInfo, accordionIndexes === 0)}
        >
          <GroupBookingPageBasicSettings />
        </AccordionTab>
        <AccordionTab
          header={headerTemplate(labels.branding, labels.brandingDesc,accordionIndexes === 1)}
        >
          <GroupBookingPageHowStep />
        </AccordionTab>
      </Accordion>
    </div>
  );
};
