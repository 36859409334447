import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { UpdateBookingPageInput } from '../../../API';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../../store/groupBookingPages';
import labels from './labels';
import { InputSwitch } from 'primereact/inputswitch';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { Chip } from 'primereact/chip';
import { useState } from 'react';

export const GroupBookingPageBasicSettings = () => {
  const dispatch = useDispatch();
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const isNameValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageNameValid);
  const bookingPageOptions = useSelector(bookingPageSelectors.selectFilteredBookingPages);
  const bookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPageIds = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPageIds);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);

  const [filteredBookingPages, setFilteredBookingPages] = useState<UpdateBookingPageInput[]>([]);
  const [localValue, setLocalValue] = useState('');

  const handleNameChange = (name: string) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ name }));
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ active }));
  };

  const handleBookingPageSelect = (e: AutoCompleteSelectEvent) => {
    dispatch(
      groupBookingPagesActions.updateGroupBookingPage({
        bookingPages: [...bookingPages, { bookingPageId: e.value.id }],
      })
    );
    setLocalValue('');
  };

  const filterBookingPages = (e: AutoCompleteCompleteEvent) => {
    const pages = bookingPageOptions.filter((page) => {
      return (
        (!e.query.trim().length ||
          page.what?.customName?.toLowerCase().match(e.query.toLowerCase()) ||
          page.displayId?.toLowerCase().match(e.query.toLowerCase())) &&
        !bookingPageIds.includes(page.id)
      );
    });
    setFilteredBookingPages(pages);
  };

  const getBookingPageItemTemplate = (option: UpdateBookingPageInput) => (
    <div className="flex gap-3">
      <div className="overflow-hidden text-overflow-ellipsis">{option.what?.customName}</div>
      <div className="bg-gray-200 px-2 border-round">{option.displayId}</div>
    </div>
  );

  const getBookingPageChipTemplate = (option: UpdateBookingPageInput) => (
    <div className="flex align-items-center py-1">
      <div className="h-1rem w-1rem border-round mr-1" style={{ backgroundColor: option.what?.color || '' }} />
      <div className="p-chip-text">
        {option.what?.customName} ({option.displayId})
      </div>
      <span
        className="ml-3 pi pi-times-circle cursor-pointer"
        onClick={() => handleBookingPageRemoveChange(option.id)}
      />
    </div>
  );

  const handleBookingPageRemoveChange = (id: string) => {
    const newBookingPages = bookingPages.filter((record) => record?.bookingPageId && record.bookingPageId !== id);
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages: newBookingPages }));
  };

  return (
    <div className="flex flex-column text-heavy-100 gap-24px p-fluid">
      <div className="w-12 flex align-items-center p-2 mb-3 border-round" style={{ background: '#e7f4fd' }}>
        <span className="pi pi-info-circle mr-2"></span>
        {labels.infoMessage}
      </div>
      <div className="grid -mb-2">
        <div className="col-12 md:col-6 flex flex-column">
          <label htmlFor="name">{labels.nameTitle}</label>
          <InputText
            id="name"
            type="text"
            placeholder={labels.namePlaceholder}
            value={groupBookingPage.name || ''}
            onChange={(e) => handleNameChange(e.target.value)}
            className={`${!isNameValid ? 'p-invalid' : ''}`}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="col-6 md:pl-4">
          <div className="flex align-items-end h-full pb-2">
            <label htmlFor="active" className="cursor-pointer mr-3">
              {labels.active}
            </label>
            <InputSwitch
              inputId="active"
              checked={!!groupBookingPage.active}
              onChange={(e) => handleActiveChange(!!e.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <div>{labels.bookingPages}</div>
          <AutoComplete
            field="id"
            itemTemplate={getBookingPageItemTemplate}
            placeholder={labels.bookingPagesDesc}
            dropdown
            dropdownMode="current"
            value={localValue}
            onChange={(e) => setLocalValue(e.value)}
            onSelect={handleBookingPageSelect}
            suggestions={filteredBookingPages}
            completeMethod={filterBookingPages}
            className={`${bookingPageIds.length < 2 ? 'p-invalid' : ''}`}
          />
          <div className="w-12">
            {bookingPageIds.map((id) => {
              const page = bookingPagesById[id];
              return <Chip key={`page-${id}`} className="my-2 mr-2" template={getBookingPageChipTemplate(page)} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
