import { Menu } from 'primereact/menu';
import { UpdateGroupBookingPageInput } from '../../../API';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { MenuItem } from 'primereact/menuitem';
import labels from './labels';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { addToWebsiteModalActions, bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import {
  groupBookingPagesActions,
  groupDeactivateModalActions,
  groupDeleteModalActions,
} from '../../../store/groupBookingPages';
import { AppDispatch } from '../../../store/rootStore';
import { styles } from './styles';
import { getBaseUrl } from '../../../services/utils';

type GroupBookingPageCardProps = {
  groupBookingPage: UpdateGroupBookingPageInput;
};
export const GroupBookingPageCard = ({ groupBookingPage }: GroupBookingPageCardProps) => {
  const { id, name, shortLink, active } = groupBookingPage;
  const dispatch = useDispatch<AppDispatch>();
  const linkedBookingPages = useSelector(bookingPageSelectors.selectLinkedBookingPages(groupBookingPage));
  const menu = useRef<Menu>(null);

  const [linkCopied, setLinkCopied] = useState(false);

  // Reset linkCopied after 3 seconds
  useEffect(() => {
    linkCopied && setTimeout(() => setLinkCopied(false), 3000);
  }, [linkCopied]);

  const handleCardClick = () => {
    handleEdit();
  };

  const handleEdit = () => {
    navigationService.navigateTo(Path.EditGroupBookingPage.replace(':groupBookingPageId', id));
  };

  const handleActivate = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupBookingPagesActions.activateGroupBookingPageRequest());
  };

  const handleDeactivate = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupDeactivateModalActions.openModal());
  };

  const handleClone = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupBookingPagesActions.cloneGroupBookingPageThunk());
  };

  const handleDelete = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupDeleteModalActions.openModal());
  };

  const handleCopyLink = () => {
    const bookingPageUrl = shortLink
      ? shortLink
      : getBaseUrl() + Path.PublicGroupBookingPage.replace(':groupBookingPageId', id);
    navigator.clipboard.writeText(bookingPageUrl);
    setLinkCopied(true);
  };

  const handleAddToWebsite = () => {
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicGroupBookingPage.replace(':groupBookingPageId', id)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  const menuItems: MenuItem[] = [
    { label: labels.edit, icon: 'pi pi-fw pi-pencil', command: handleEdit },
    {
      label: active ? labels.deactivate : labels.activate,
      icon: active ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: active ? handleDeactivate : handleActivate,
    },
    { label: labels.clone, icon: 'pi pi-fw pi-clone', command: handleClone },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete },
  ];

  return (
    <>
      <div className="relative h-full" onClick={handleCardClick}>
        <div
          className="card w-full absolute border-1 border-round surface-border shadow-none"
          style={styles.backgroundCard}
        />
        <div
          className={`relative flex flex-column card border-1 border-round surface-border cursor-pointer hover:bg-gray-50 shadow-none ${
            active ? '' : 'bg-gray-100 text-gray-500'
          }`}
          style={styles.card}
        >
          <div className="flex">
            <div className="static sm:relative flex-container -mt-2 -mr-2 ml-auto" onClick={(e) => e.stopPropagation()}>
              <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
              <Button rounded text icon="pi pi-cog text-lg" onClick={(event) => menu.current?.toggle(event)} />
            </div>
          </div>

          <div className=" flex flex-row align-items-end">
            <div className="text-xl font-medium overflow-hidden text-overflow-ellipsis white-space-nowrap">{name}</div>
          </div>
          <div className="flex flex-column mt-auto mt-1 mb-4" style={styles.cardDescription}>
            <div className="font-light" style={styles.textOverlappingTwoLines}>
              {linkedBookingPages.map((page, index) => (
                <Fragment key={`name_${page.id}`}>
                  <span>
                    {index > 0 ? ', ' : ''}
                    {page.what?.customName}
                  </span>
                </Fragment>
              ))}
            </div>
          </div>

          <div className="flex mt-auto -mb-3 -ml-3">
            {active && (
              <>
                <Button
                  icon={linkCopied ? 'pi pi-check' : 'pi pi-copy'}
                  label={linkCopied ? labels.copiedLink : labels.copyLink}
                  text
                  onClick={(e) => {
                    e.stopPropagation(); // to prevent card click during click on inner elements
                    handleCopyLink();
                  }}
                />
                <Button
                  icon="pi pi-globe"
                  label={labels.addToSite}
                  text
                  onClick={(e) => {
                    e.stopPropagation(); // to prevent card click during click on inner elements
                    handleAddToWebsite();
                  }}
                />
              </>
            )}
            {!active && (
              <>
                <Button
                  label={labels.activate}
                  text
                  onClick={(e) => {
                    e.stopPropagation(); // to prevent card click during click on inner elements
                    handleActivate();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
