export const styles = {
  textOverlappingTwoLines: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    webkitLineClamp: '2',
    webkitBoxOrient: 'vertical',
  },
  card: {
    height: 'calc(100% + 2px)',
  },
  backgroundCard: {
    height: 'calc(100% + 2px)',
    top: '6px',
    left: '6px',
  },
  cardDescription: {
    minHeight: '3rem',
  },
};
