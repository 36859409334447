import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import {
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
  addToWebsiteModalActions,
} from '../../store/bookingPages';
import {
  BookingPageWhatStep,
  BookingPageHowStep,
  BookingPageCalendarStep,
  BookingPageSettingsStep,
  BookingPageCloneModal,
  BookingPageDeleteModal,
  BookingPagePreviewModal,
  BookingPageAddToWebsiteModal,
  BookingPageWhereStep,
  BookingPageWhenStep,
  BookingPageAdditionalRulesStep,
  BookingPageNotificationStep,
  BookingPageConfirmationStep,
  BookingPageWhoStep,
} from '../../components/bookingPages';
import { ConfirmationModal, Preloader, SectionTitle } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { BookingPageLabelsInput } from '../../API';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { navigationService } from '../../services/NavigationService';
import { BookingPageConfirmOverrideModal } from '../../components/bookingPages/bookingPageConfirmOverrideModal/BookingPageConfirmOverrideModal';
import { useUserNavigation } from '../../hooks/useUserNavigation';

export const EditBookingPage = () => {
  const { bookingPageId: bookingPageIdParam } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { isInitialVisit } = useUserNavigation(); // checking if the app was initialized on current url
  const isFetching = useSelector(bookingPageSelectors.selectIsFetching);
  const isEdited = useSelector(bookingPageSelectors.selectIsEdited);
  const isExistingBookingPage = useSelector(bookingPageSelectors.selectIsExistingBookingPage);
  const isActive = useSelector(bookingPageSelectors.selectIsActive);
  const bookingPage = useSelector(bookingPageSelectors.selectBookingPage);
  const bookingPageId = useSelector(bookingPageSelectors.selectId);
  const bookingPageDisplayId = useSelector(bookingPageSelectors.selectDisplayId);
  const bookingPageLink = useSelector(bookingPageSelectors.selectShortLink);
  const calendarInfo = useSelector(bookingPageSelectors.selectCalendarInfo);
  const settingsInfo = useSelector(bookingPageSelectors.selectSettingsInfo);
  const styleForPreview = useSelector(bookingPageSelectors.selectStyleForPreview);
  const isWhatStepValid = useSelector(bookingPageSelectors.selectIsWhatStepValid);
  const isCalendarStepValid = useSelector(bookingPageSelectors.selectIsCalendarStepValid);

  const isWhenStepValid = useSelector(bookingPageSelectors.selectIsWhenStepValid);
  const isWhoStepValid = useSelector(bookingPageSelectors.selectIsWhoStepValid);
  const isWhereStepValid = useSelector(bookingPageSelectors.selectIsWhereStepValid);
  const isNotificationsStepValid = useSelector(bookingPageSelectors.selectIsNotificationsStepValid);
  const isConfirmationStepValid = useSelector(bookingPageSelectors.selectIsConfirmationStepValid);
  const whatInfo = useSelector(bookingPageSelectors.selectWhatInfo);
  const whoInfo = useSelector(bookingPageSelectors.selectWhoInfo);

  const accordionIndexes = useSelector(bookingPageSelectors.selectAccordionIndexes);
  const lockedTooltip = useSelector(bookingPageSelectors.selectLockedTooltip);

  const bookingTemplateId = useSelector(bookingPageSelectors.selectBookingTemplateId);
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  const bookingTemplateName = useSelector(bookingPageSelectors.selectBookingTemplateName);

  const isBookingPageCreate = useSelector(bookingPageSelectors.selectIsBookingPageCreate());
  const isBookingPageEdit = useSelector(bookingPageSelectors.selectIsBookingPageEdit());
  const isBookingPageDelete = useSelector(bookingPageSelectors.selectIsBookingPageDelete());

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (bookingPageIdParam && bookingPageIdParam !== 'new') {
      dispatch(bookingPagesActions.getBookingPageRequest({ id: bookingPageIdParam, isInitialVisit }));
    } else {
      // it will set default Booking Page
      dispatch(bookingPagesActions.getBookingPageRequest({ id: '', isInitialVisit }));
    }

    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, [isInitialVisit]);

  const handleActivate = () => {
    dispatch(
      bookingPagesActions.activateBookingPageRequest({
        ...bookingPage,
        what: { ...bookingPage.what, isActive: !isActive },
      })
    );
  };

  const handleClone = () => {
    dispatch<any>(bookingPagesActions.cloneBookingPageThunk()); // TODO: any
  };

  const handleDelete = () => {
    dispatch(bookingPagesActions.selectBookingPage(bookingPageId!));
    dispatch(deleteBookingPagesModalActions.openModal());
  };

  const handleViewBookingPage = () => {
    bookingPageLink && window.open(bookingPageLink, '_blank');
  };

  const handleAddToWebsite = () => {
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicBookingPage.replace(':bookingPageId', bookingPageId)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  const handleCopyLink = () => {
    bookingPageLink && navigator.clipboard.writeText(bookingPageLink);
  };

  const menuItems = [
    {
      label: isActive ? labels.deactivate : labels.activate,
      icon: isActive ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleActivate,
      visible: isBookingPageEdit,
    },
    { label: labels.clone, icon: 'pi pi-fw pi-clone', command: handleClone, visible: isBookingPageCreate },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete, visible: isBookingPageDelete },
  ];

  const handleFirstAccordionIndexChange = (index: number | null) => {
    dispatch(bookingPagesActions.updateAccordionIndexes({ first: index }));
  };

  const handleSecondAccordionIndexChange = (index: number | null) => {
    dispatch(bookingPagesActions.updateAccordionIndexes({ second: index }));
  };

  const handleAccordion1TabChange = (index: number | null) => {
    handleFirstAccordionIndexChange(index);
    handleSecondAccordionIndexChange(null);
  };
  const handleAccordion2TabChange = (index: number | null) => {
    handleSecondAccordionIndexChange(index);
    handleFirstAccordionIndexChange(null);
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>, isGlobalSave: boolean) => {
    e.stopPropagation(); // for no close the accordion
    if (!isWhatStepValid) {
      handleAccordion1TabChange(0);
      return;
    }
    if (!isWhoStepValid) {
      handleAccordion1TabChange(1);
      return;
    }
    if (!isWhereStepValid) {
      handleAccordion1TabChange(2);
      return;
    }
    if (!isWhenStepValid) {
      handleAccordion1TabChange(3);
      return;
    }
    if (!isNotificationsStepValid) {
      handleAccordion2TabChange(2);
      return;
    }
    if (!isConfirmationStepValid) {
      handleAccordion2TabChange(3);
      return;
    }
    if (!isCalendarStepValid) {
      handleAccordion2TabChange(4);
      return;
    }

    isExistingBookingPage
      ? dispatch(bookingPagesActions.saveBookingPageRequest(isGlobalSave))
      : dispatch(bookingPagesActions.createBookingPageRequest(isGlobalSave));
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion

    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmCancel = () => {
    navigationService.navigateTo(Path.BookingPages);
    dispatch(bookingPagesActions.clearBookingPage());
  };

  const handleOverride = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // for no close the accordion
    dispatch(bookingPagesActions.overrideBookingTemplate());
  };

  const renderSaveCancelButtons = (isGlobalSave: boolean) => (
    <>
      <Button className="ml-2 bg-white" label={labels.cancel} outlined onClick={handleCancel} />
      <Button
        className="ml-2"
        label={labels.save}
        onClick={(e) => handleSave(e, isGlobalSave)}
        disabled={!!lockedTooltip}
      />
    </>
  );

  const renderAccordionTab = (
    titleElement: ReactJSXElement,
    stepElement: ReactJSXElement,
    showHeaderButtons = false,
    showBottomButtons = false,
    stepInfo: string | null,
    showLockedBlock = false
  ) => (
    <AccordionTab
      contentClassName={`${lockedTooltip || (showLockedBlock && hasTemplate) ? 'pointer-events-none' : ''}`}
      header={
        <>
          {showHeaderButtons && (
            <div className="absolute right-0 mr-2 h-1rem flex align-items-center">{renderSaveCancelButtons(false)}</div>
          )}
          <div className="font-normal h-1rem flex flex-column justify-content-center">
            <div>{titleElement}</div>
            {stepInfo && <div className="mt-1 text-sm text-gray-500">{stepInfo}</div>}
          </div>
        </>
      }
    >
      {!lockedTooltip && showLockedBlock && hasTemplate && (
        <div className="-mt-3 -mx-3 mb-3 bg-blue-100 py-2 px-3 pointer-events-auto">
          <i className="pi pi-lock mr-2" />
          {labels.bookingTemplateLinkTooltip}
          {bookingTemplateId && (
            <>
              <Link
                className="text-color underline hover:text-primary"
                target="_blank"
                to={Path.EditBookingTemplate.replace(':bookingTemplateId', bookingTemplateId)}
              >
                {bookingTemplateName}
              </Link>
              &nbsp;--&nbsp;
              <span onClick={(e) => handleOverride(e)} className="text-color underline hover:text-primary">
                {labels.bookingTemplateLinkOverride}
              </span>
            </>
          )}
        </div>
      )}
      <div className="lg:p-2">{stepElement}</div>
      {showBottomButtons && (
        <div className="flex justify-content-end -mx-3 -mb-3 p-2 border-top-1 border-gray-300 p-accordion-header-text">
          {renderSaveCancelButtons(false)}
        </div>
      )}
    </AccordionTab>
  );

  const handleLabelsSave = (labels: BookingPageLabelsInput) => {
    dispatch(bookingPagesActions.updateHowStep(labels));
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <BookingPageCloneModal />
      <BookingPageDeleteModal />
      <BookingPagePreviewModal bookingPage={{ ...bookingPage, style: styleForPreview }} onSave={handleLabelsSave} />
      <BookingPageAddToWebsiteModal />
      <BookingPageConfirmOverrideModal />

      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        cancelButtonProps={{ className: 'w-auto mr-0' }}
        confirmButtonProps={{ className: 'w-auto ml-4 mr-0' }}
        onConfirm={handleConfirmCancel}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <div className="card mb-3 flex justify-content-between align-items-center sumo-header-bg">
        <SectionTitle id="editBookingPageTitle" icon="pi-desktop" title={labels.title} tooltip={labels.tooltip} />
        <div className="flex align-items-center -my-2">
          <div className="p-accordion-header-text">
            <Button text label={labels.viewBookingPage} onClick={handleViewBookingPage} disabled={!bookingPageLink} />
            <Button
              text
              icon="pi pi-copy"
              label={labels.copyLink}
              onClick={handleCopyLink}
              disabled={!bookingPageLink}
            />
            <Button text label={labels.addToWebsite} onClick={handleAddToWebsite} />
            {(isBookingPageCreate || isBookingPageEdit || isBookingPageDelete) && (
              <>
                <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
                <Button
                  className="pr-2"
                  text
                  icon="pi pi-cog text-lg"
                  onClick={(event) => menu.current?.toggle(event)}
                />
              </>
            )}
            {accordionIndexes.first === null &&
              accordionIndexes.second === null &&
              (isEdited ? (
                renderSaveCancelButtons(true)
              ) : (
                <Button icon="pi pi-chevron-left" label={labels.backToList} onClick={handleConfirmCancel} />
              ))}
          </div>
        </div>
      </div>

      <div className="text-xl pl-1 ml-3 mb-2 mt-4">
        {isExistingBookingPage ? labels.edit + ' "' + whatInfo + '"' : labels.newBookingPage}
        {!!lockedTooltip && (
          <>
            <Tooltip target=".locked" />
            <span
              className="locked bg-yellow-100 border-round ml-3 p-1 px-2"
              style={{ cursor: 'default' }}
              data-pr-tooltip={lockedTooltip}
              data-pr-position="top"
            >
              <i className="pi pi-lock mr-2" />
              {labels.locked}
            </span>
          </>
        )}
        {isExistingBookingPage && (
          <span className="bg-gray-200 px-2 border-round ml-3">{bookingPageDisplayId}</span>
        )}
      </div>

      <Accordion
        activeIndex={accordionIndexes.first}
        onTabChange={(e) => (e.index === null || typeof e.index === 'number') && handleAccordion1TabChange(e.index)}
      >
        {renderAccordionTab(
          <>
            <b>{labels.whatTitlePart1}</b> {labels.whatTitlePart2}
          </>,
          <BookingPageWhatStep />,
          accordionIndexes.first === 0,
          false,
          whatInfo
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whoTitlePart1}</b> {labels.whoTitlePart2}
          </>,
          <BookingPageWhoStep />,
          accordionIndexes.first === 1,
          true,
          whoInfo
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whereTitlePart1}</b>
            {labels.whereTitlePart2}
          </>,
          <BookingPageWhereStep />,
          accordionIndexes.first === 2,
          true,
          '',
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.whenTitlePart1}</b>
            {labels.whenTitlePart2}
          </>,
          <BookingPageWhenStep />,
          accordionIndexes.first === 3,
          true,
          '',
          true
        )}
      </Accordion>

      <div className="text-xl pl-1 ml-3 mb-2 mt-4">{labels.additionalOptions}</div>

      <Accordion
        activeIndex={accordionIndexes.second}
        onTabChange={(e) => (e.index === null || typeof e.index === 'number') && handleAccordion2TabChange(e.index)}
      >
        {renderAccordionTab(
          <>
            <b>{labels.howTitlePart1}</b> {labels.howTitlePart2}
          </>,
          <BookingPageHowStep />,
          accordionIndexes.second === 0,
          false,
          labels.howDescription,
          true
        )}
        {renderAccordionTab(
          <>
            {labels.inviteeTitlePart1}
            <b>{labels.inviteeTitlePart2}</b>
          </>,
          <BookingPageAdditionalRulesStep />,
          accordionIndexes.second === 1,
          true,
          labels.inviteeSubTitle,
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.notificationsTitlePart1}</b>
            {labels.notificationsTitlePart2}
          </>,
          <BookingPageNotificationStep />,
          accordionIndexes.second === 2,
          true,
          '',
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.confirmationTitlePart1}</b>
            {labels.confirmationTitlePart2}
          </>,
          <BookingPageConfirmationStep />,
          accordionIndexes.second === 3,
          false,
          '',
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.calendarTitlePart1}</b> {labels.calendarTitlePart2}
          </>,
          <BookingPageCalendarStep />,
          accordionIndexes.second === 4,
          true,
          calendarInfo,
          true
        )}
        {renderAccordionTab(
          <>
            <b>{labels.pageTitlePart1}</b> {labels.pageTitlePart2}
          </>,
          <BookingPageSettingsStep />,
          accordionIndexes.second === 5,
          false,
          settingsInfo,
          true
        )}
      </Accordion>

      {/* <div className="card mt-6 sumo-header-bg">
        <div className="flex align-items-center mb-2">
          <i className="pi pi-info-circle"></i>
          <span className="ml-3 font-bold">{labels.bookingTemplateTitle}</span>
        </div>
        <div>
          <span>{labels.bookingTemplatePart1}</span>
          <Link to={Path.BookingTemplates} className="text-color underline hover:text-primary">
            {labels.bookingTemplatePart2}
          </Link>
          <span>{labels.bookingTemplatePart3}</span>
        </div>
      </div> */}
    </div>
  );
};
