import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { globalSelectors } from '../global';
import { bookingPageSelectors } from '../bookingPages';
import { CSSProperties } from 'react';
import { isStylesEqual } from '../bookingPages/utils';
import { isObjectDeepEqual } from '../../services/utils';
import { t } from '../../i18n/i18n';

const groupBookingPagesState = (state: State) => state.groupBookingPages;

const selectIsFetching = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.isFetching);
const selectError = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.error);
const selectIsEdited = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.isEdited);
const selectCloneName = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.cloneName);
const selectGroupBookingPages = createSelector(
  groupBookingPagesState,
  (groupBookingPages) => groupBookingPages.groupBookingPages
);
const selectGroupBookingPage = createSelector(
  groupBookingPagesState,
  (groupBookingPages) => groupBookingPages.groupBookingPage
);

const selectSearchedGroupBookingPages = createSelector(
  bookingPageSelectors.selectFilteredBookingPages,
  selectGroupBookingPages,
  globalSelectors.selectSearchString,
  (filteredBookingPages, groupBookingPages, searchString) =>
    groupBookingPages
      .filter(
        (groupBookingPage) =>
          groupBookingPage.name?.toLowerCase().includes(searchString.toLowerCase()) &&
          groupBookingPage.bookingPages?.some((page) =>
            filteredBookingPages.some((bookingPage) => bookingPage.id === page?.bookingPageId)
          )
      )
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
);

const selectGroupBookingPageName = createSelector(selectGroupBookingPage, (page) => page.name);
const selectIsGroupBookingPageNameValid = createSelector(selectGroupBookingPageName, (name) => Boolean(name));
const selectGroupBookingPageBookingPages = createSelector(selectGroupBookingPage, (page) => page.bookingPages || []);
const selectGroupBookingPageBookingPageIds = createSelector(
  selectGroupBookingPageBookingPages,
  (pages) => pages?.map((page) => page?.bookingPageId).filter((id): id is string => !!id) || []
);
const selectBasicInfo = createSelector(
  selectGroupBookingPageName,
  selectGroupBookingPageBookingPageIds,
  bookingPageSelectors.selectBookingPagesById,
  (name, ids, bookingPages) => {
    const bookingPageNames = ids.map((id) => id && bookingPages[id].what?.customName);
    return (
      (name ? `${name} - ` : '') +
      bookingPageNames.filter((vlaue, index) => index < 3).join(', ') +
      (bookingPageNames.length > 3 ? `, ${t('EditGroupBookingPage:AND')} ${bookingPageNames.length - 3} ${t('EditGroupBookingPage:MORE')}` : '')
    );
  }
);

const selectGroupBookingPageStyle = createSelector(selectGroupBookingPage, (page) => page.style || {});
const selectGroupBookingPageBackground = createSelector(selectGroupBookingPageStyle, (style) => style?.backgroundImage);
const selectFontStyleMainText = createSelector(
  selectGroupBookingPageStyle,
  (style) => ({ color: style.lookAndFeel?.fontMainText || '' } as CSSProperties)
);
const selectGroupBookingPageLabels = createSelector(selectGroupBookingPage, (page) => page.labels);
const selectBrandingBookingPagId = createSelector(selectGroupBookingPage, (page) => page.brandingBookingPageId);

const selectIsGroupBookingPageValid = createSelector(
  selectGroupBookingPage,
  selectIsGroupBookingPageNameValid,
  (page, isNameValid) => (page.bookingPages?.length || 0) > 1 && isNameValid
);

const selectIsCloneNameValid = createSelector(selectCloneName, (name) => Boolean(name));

const selectGroupBookingPageOptions = createSelector(
  selectGroupBookingPageBookingPageIds,
  bookingPageSelectors.selectBookingPagesById,
  (ids, bookingPages) => ids.map((id) => id && { value: id, label: bookingPages[id].what?.customName || '' })
);

const selectIsBookingPageStyleEquals = createSelector(
  selectGroupBookingPageBookingPageIds,
  bookingPageSelectors.selectBookingPagesById,
  (ids, bookingPages) => {
    const bookingPageRecords = ids.map((id) => bookingPages[id]);

    return bookingPageRecords.every(
      (page) =>
        isStylesEqual(page.style, bookingPageRecords[0].style) &&
        isObjectDeepEqual(page.labels, bookingPageRecords[0].labels)
    );
  }
);

const selectTopHeaderStyle = createSelector(
  selectGroupBookingPageStyle,
  (style) =>
    ({
      backgroundColor: style.lookAndFeel?.siteColorPrimary || '',
      borderColor: style.lookAndFeel?.siteColorPrimary || '',
    } as CSSProperties)
);

const selectHeaderStyle = createSelector(
  selectGroupBookingPageStyle,
  (style) =>
    ({
      fontFamily: `"${style.lookAndFeel?.fontHeader}"`,
      color: style.lookAndFeel?.fontColorHeader || '',
    } as CSSProperties)
);

export const groupBookingPagesSelectors = {
  selectIsFetching,
  selectError,
  selectIsEdited,
  selectCloneName,
  selectGroupBookingPages,
  selectGroupBookingPage,

  selectSearchedGroupBookingPages,
  selectGroupBookingPageName,
  selectIsGroupBookingPageNameValid,
  selectIsGroupBookingPageValid,
  selectGroupBookingPageBookingPages,
  selectGroupBookingPageBookingPageIds,
  selectBasicInfo,
  selectIsCloneNameValid,
  selectGroupBookingPageOptions,
  selectIsBookingPageStyleEquals,
  selectGroupBookingPageStyle,
  selectGroupBookingPageLabels,
  selectGroupBookingPageBackground,
  selectFontStyleMainText,
  selectBrandingBookingPagId,

  selectHeaderStyle,
  selectTopHeaderStyle,
};
