import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { Label, Preloader } from '../../components/common';
import sumoIconLight from '../../assets/images/sumoIconLight.png';
import errorImage from '../../assets/images/error_desert.png';
import { bookingPageSelectors } from '../../store/bookingPages';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { eventActions } from '../../store/publicBookingPage';
import labels from './labels';

export const PublicGroupBookingPage = () => {
  const dispatch = useDispatch();
  const { groupBookingPageId } = useParams();
  const isFetching = useSelector(groupBookingPagesSelectors.selectIsFetching);
  const error = useSelector(groupBookingPagesSelectors.selectError);
  const name = useSelector(groupBookingPagesSelectors.selectGroupBookingPageName);
  const style = useSelector(groupBookingPagesSelectors.selectGroupBookingPageStyle);
  const fontStyleMainText = useSelector(groupBookingPagesSelectors.selectFontStyleMainText);
  const bookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);

  const topHeaderStyle = useSelector(groupBookingPagesSelectors.selectTopHeaderStyle);
  const headerStyle = useSelector(groupBookingPagesSelectors.selectHeaderStyle);

  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get('hideHeader') === 'true';
  const iframe = searchParams.get('iframe') === 'true';

  useEffect(() => {
    dispatch(
      groupBookingPagesActions.getPublicGroupBookingPageRequest({
        id: groupBookingPageId || '',
      })
    );
  }, []);

  const handleCardClick = (id: string) => {
    dispatch(eventActions.setGroupBookingPageId(groupBookingPageId || ''));
    navigationService.navigateTo(Path.PublicBookingPage.replace(':bookingPageId', id));
  };

  const containerClassName = `flex justify-content-center min-h-screen bg-cover bg-center ${
    iframe ? '' : 'md:pt-6 md:pb-3'
  }`;
  const containerStyle = style.backgroundImage
    ? { backgroundImage: `url(${style.backgroundImage})` }
    : { backgroundColor: `${style.lookAndFeel?.siteColorBackground}` };

  const innerContainerStyle = iframe ? {} : { maxWidth: '800px' };
  const contentClassName = `py-3 flex-1 ${
    iframe ? 'px-3 md:px-4' : 'px-4 md:py-4 md:flex-none md:border-round-bottom-xl'
  }`;

  return !isFetching || error ? (
    <div className={containerClassName} style={containerStyle}>
      <div className="w-full flex flex-column" style={innerContainerStyle}>
        <style dangerouslySetInnerHTML={{ __html: style.css || '' }} />

        {isFetching && <Preloader />}

        {!hideHeader && (
          <div
            className={`flex align-items-center px-4 py-2 ${iframe ? '' : 'md:border-round-top-xl'}`}
            style={topHeaderStyle}
          >
            <div className="w-fit md:w-3 flex align-items-center pr-2">
              <img src={style.avatar || ''} className="max-h-4rem" />
            </div>
            <div className="flex-1 text-center text-xl font-semibold">
              <Label
                editClassName="hover:text-primary"
                textClassName="text-xl font-semibold text-center"
                textStyle={headerStyle}
                value={name || ''}
              />
            </div>
            <div className="w-0 md:w-3"></div>
          </div>
        )}

        <div
          className={contentClassName}
          style={{ backgroundColor: `rgba(255,255,255,${Number(style.opacity) / 100})` }}
        >
          {!error ? (
            <>
              <div className="text-xl font-semibold pl-3 ml-1 -mr-1 mt-3">{labels.title}</div>
              <div className="grid -mb-2 mt-2 max-h-30rem overflow-y-auto">
                {bookingPages.map((item) => {
                  const id = item?.bookingPageId || '';
                  const page = bookingPagesById[id];
                  return (
                    <div key={id} className="col-12 lg:col-6">
                      <div
                        className="card p-4 h-full border-1 surface-border shadow-none cursor-pointer hover:bg-gray-50"
                        onClick={() => handleCardClick(id)}
                      >
                        <div className="flex align-items-center gap-2">
                          <div
                            className="w-2rem h-2rem border-round"
                            style={{ backgroundColor: page.what?.color || '' }}
                          />
                          <div className="font-semibold">{page.what?.customName}</div>
                        </div>
                        <div style={{ ...fontStyleMainText, whiteSpace: 'pre-line' }} className="mt-2">
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="card mb-2 border-1 surface-border shadow-none">
              <div className="flex flex-column justify-content-center align-items-center">
                <div className="text-xl my-4">{error?.toString()}</div>
                <div>
                  <img src={errorImage} alt="error_desert" />
                </div>
              </div>
            </div>
          )}
        </div>

        {!iframe && style.footer && (
          <div className="hidden md:block mt-auto pt-2 text-center">
            <img src={sumoIconLight} alt="Logo" className="h-4rem w-4rem" />
            <div dangerouslySetInnerHTML={{ __html: style.footer || '' }} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <Preloader />
  );
};
